import PageSlide from "../PageSlide";
import ServicesContent from "./content/ServicesContent";
import ServicesSwiperContent from "./content/ServicesSwiperContent";
import "./ServicesSlide.css";

const ServicesSlide = () => {
    return (
        <PageSlide id={"services"}>
            <ServicesContent/>
            <ServicesSwiperContent/>
        </PageSlide>
    );
};

export default ServicesSlide;
