import "./MainContent.css";
import HomeSlide from "./slide/home/HomeSlide";
import AboutSlide from "./slide/about/AboutSlide";
import ServicesSlide from "./slide/services/ServicesSlide";
import ClientsSlide from "./slide/clients/ClientsSlide";
import ZepetoPortfolioSlide from "./slide/portfolio/zepeto/ZepetoPortfolioSlide";
import GatherTownPortfolioSlide from "./slide/portfolio/gather-town/GatherTownPortfolioSlide";
import VirtualOfficeSlide from "./slide/virtual-office/VirtualOfficeSlide";
import ContactSlide from "./slide/contact/ContactSlide";
import MainFooter from "../footer/MainFooter";
import { Redirect, Route, Switch } from "react-router-dom";
import ZepetoPortfolioDetailSlide from "./slide/portfolio-detail/zepeto/ZepetoPortfolioDetailSlide";
import GatherTownPortfolioDetailSlide from "./slide/portfolio-detail/gather-town/GatherTownPortfolioDetailSlide";
import ZepPortfolioDetailSlide from "./slide/portfolio-detail/zep/ZepPortfolioDetailSlide";
import EtcPortfolioDetailSlide from "./slide/portfolio-detail/etc/EtcPortfolioDetailSlide";
import VllagePrivacy from './slide/terms/VllagePrivacy';
import VllageService from './slide/terms/VllageService';

const MainContent = () => {
  return (
    <div id={"main-content"}>
      <Switch>
        <Route path={"/"} exact>
          <HomeSlide />
          <AboutSlide />
          <ServicesSlide />
          <ClientsSlide />
        </Route>
        <Route path={"/portfolio"}>
          <Route path={"/portfolio/zepeto"}>
            <ZepetoPortfolioSlide />
          </Route>
          <Route path={"/portfolio/gather-town"}>
            <GatherTownPortfolioSlide />
          </Route>
          <Route path={"/portfolio/detail"}>
            <Route path={"/portfolio/detail/zepeto"}>
              <ZepetoPortfolioDetailSlide />
            </Route>
            <Route path={"/portfolio/detail/zep"}>
              <ZepPortfolioDetailSlide />
            </Route>
            <Route path={"/portfolio/detail/gather-town"}>
              <GatherTownPortfolioDetailSlide />
            </Route>
            <Route path={"/portfolio/detail/etc"}>
              <EtcPortfolioDetailSlide />
            </Route>
          </Route>
        </Route>
        <Route path={"/contact"}>
          <ContactSlide />
        </Route>
        <Route path={"/virtual-office"}>
          <VirtualOfficeSlide />
        </Route>
        <Route path={"/terms"}>
          <Route path={"/terms/vllageprivacy"}>
            <VllagePrivacy />
          </Route>
          <Route path={"/terms/vllageservice"}>
            <VllageService />
          </Route>
        </Route>
        <Redirect to={"/"} />
      </Switch>
      <MainFooter />
    </div>
  );
};

export default MainContent;
