import axios from "axios";
import "./ContactForm.css";
import SelectBox from "../../../../../common/select/SelectBox";
import { useState } from "react";
import InputBox from "../../../../../common/input/InputBox";
import TextareaBox from "../../../../../common/textarea/TextareaBox";

const ContactForm = () => {
  const [isApply, setIsApply] = useState(false);
  const [service, setService] = useState("");
  const [price, setPrice] = useState("");
  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [contents, setContents] = useState("");

  const handleConfirmClick = (e) => {
    if (!company || !email || !phone || !name) {
      alert(
        `다음 내용은 필수입니다.\n\n${name ? "" : "성함"} ${
          company ? "" : "회사명"
        }  ${email ? "" : "이메일"} ${
          phone ? "" : "전화번호"
        } \n\n모든 정보가 입력되었는데도 이 메시지가 발생하시면 불편하시겠지만 help@devmate.net으로 직접 문의주시면 감사하겠습니다.`
      );
      return;
    }

    setIsApply(true);
    const text = `소속: ${company} 이름 ${name} / 연락처: ${phone} / 메일: ${email} / 문의내용: ${contents} / 서비스종류 : ${service} / 예산 ${price} / !! 문의 내용 확인 후 제대로 받았고, 검토중이라는 메일 송부 필수 !!`;

    axios({
      method: "post",
      url: "https://lpuln9bzr3.execute-api.ap-northeast-2.amazonaws.com/devmate-contact",
      headers: {
        origin: "https://devmate.net",
      },
      data: {
        text,
      }
    })
    .then(() => {

    })
    .catch(() => {

    })

    axios({
      method: "post",
      url: "https://utaha.moe/devmatemail",
      data: {
        text: text,
      },
    })
      .then(() => {
        var _nasa={};
        // no-undef
        if(window?.wcs) {
          _nasa["cnv"] = window.wcs.cnv("4","1"); // 전환유형, 전환가치 설정해야함. 설치매뉴얼 참고
          // eslint-disable-next-line
          wcs_do(_nasa);
        }
        alert("문의 신청이 완료되었습니다. 담당자검토 후 연락드리겠습니다!");
      })
      .catch(() => {
        alert(
          "죄송합니다. 현재 통신 문제로 문의 신청에 어려움이 있습니다. help@devmate.net으로 문의주시면 연락드리겠습니다!"
        );
      })
      .finally(() => {
        setIsApply(false);
      });
    e.stopPropagation();
  };

  return (
    <div id={"contact-form"} className={"content"}>
      <header className={"eng"}>CONTACT</header>
      <article>
        <div className={"title"}>
          아래의 폼을 작성해주시면 자사 매니저가 영업일 기준 1일 이내에
          연락드리겠습니다.
        </div>
        <form>
          <div>
            <SelectBox
              items={[
                "메타버스 행사 대행 및 기획",
                "Gather Town 월드맵 구축",
                "ZEP 월드맵 구축",
                "ZEPETO 월드맵 구축",
                "커스텀 메타버스(유니버리움) 구축",
                "기타 메타버스 월드맵 구축",
                "ZEPETO 브랜딩 아이템 제작",
              ]}
              value={service}
              placeholder={"서비스 희망 항목을 선택해주세요."}
              onSelect={(value) => setService(value)}
            />
            <SelectBox
              items={[
                "미정",
                "500만원 ~ 1000만원",
                "1000만원 ~ 3000만원",
                "3000만원 ~ 5000만원",
                "5000만원 ~ 1억",
                "1억 이상",
              ]}
              value={price}
              placeholder={"프로젝트 진행 예산을 선택해주세요"}
              onSelect={(value) => setPrice(value)}
            />
            <InputBox
              value={name}
              placeholder={"성함 (필수)"}
              onChange={(value) => setName(value)}
            />
            <InputBox
              value={company}
              placeholder={"회사명 (필수)"}
              onChange={(value) => setCompany(value)}
            />
            <InputBox
              value={email}
              placeholder={"이메일 (필수)"}
              onChange={(value) => setEmail(value)}
            />
            <InputBox
              value={phone}
              placeholder={"전화번호 (필수)"}
              onChange={(value) => setPhone(value)}
            />
          </div>
          <div>
            <TextareaBox
              className={"contact-form-contents"}
              value={contents}
              placeholder={"문의 내용"}
              onChange={(value) => setContents(value)}
            />
          </div>
        </form>
        <button
          className={"confirm"}
          onClick={handleConfirmClick}
          disabled={isApply}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.3333 4.16699L15.8333 6.66699M5 12.5003L11.6667 5.83366L14.1667 8.33366L7.5 15.0003H5L5 12.5003Z"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <div>{!isApply ? "문의 보내기" : "제출 중..."}</div>
        </button>
      </article>
    </div>
  );
};

export default ContactForm;
