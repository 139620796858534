import "./ContactProcessItemPeriod.css";

const ContactProcessItemPeriod = ({count, children}) => {
    return (
        <div
            className={"contact-process-item-period"}
            data-period-count={count}
        >
            <div className={"period eng"}>
                {children}
            </div>
        </div>
    )
};

export default ContactProcessItemPeriod;
