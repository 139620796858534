import "./PortfolioPhoto.css";
import PopupStore from "../../../../../../store/popupStore";

const popupStore = PopupStore.getInstance();

const PortfolioPhoto = ({photo, title, type, description}) => {
    const onClick = () => {
        popupStore.currentType = type;
    };

    return (
        <div className={"portfolio-photo"} onClick={onClick}>
            <div
                className={"background"}
                style={{backgroundImage: `url(${photo})`}}
            />
            <div className={"blur"}/>
            <div className={"info"}>
                <header>{title}</header>
                <article>{description}</article>
            </div>
            <div className={"cover"}/>
        </div>
    );
};

export default PortfolioPhoto;
