import "./PortfolioPopupContent.css";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';

const PortfolioPopupContent = ({photos, children}) => {
    return (
        <div className={"portfolio-popup-content"}>
            <div className={"photos"}>
                <Swiper pagination={true} modules={[Pagination]} className="swiper-portfolio">
                {photos.map((photo, idx) => (
                    <SwiperSlide>
                        <img className="photos" src={photo} alt={"portfolio"} key={idx}/>
                    </SwiperSlide>
                ))}
                </Swiper>
            </div>
            <div className={"content"}>
                {children}
            </div>
        </div>
    );
};

export default PortfolioPopupContent;
