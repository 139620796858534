import React from "react";
import classNames from "classnames";
import "./ClientsCompanyRow.css";

const ClientsCompanyRow = React.forwardRef(({className, style, images}, ref) => {
    return (
        <div
            className={classNames("clients-company-row", className)}
            style={style}
            ref={ref}
        >
            <div className={"content"}>
                {images.map((src, i) => (
                    <img src={src} key={i} alt={"logo"}/>
                ))}
            </div>
        </div>
    );
});

export default ClientsCompanyRow;
