import "./ContactProcess.css";
import ContactProcessItemList from "./item/ContactProcessItemList";

const ContactProcess = () => {
    return (
        <div id={"contact-process"} className={"content"}>
            <header className={"eng"}>PROCESS</header>
            <article>
                <ContactProcessItemList/>
            </article>
        </div>
    );
};

export default ContactProcess;
