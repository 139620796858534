import "./PopupTitle.css";
import classNames from "classnames";

const PopupTitle = ({className, children}) => {
    return (
        <div className={classNames(className, "popup-title")}>
            {children}
        </div>
    );
};

export default PopupTitle;
