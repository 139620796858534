import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import "./HomeSwiperContent.css";
import HomeCard1 from "./cards/card1/HomeCard1";
import HomeCard2 from "./cards/card2/HomeCard2";
import HomeCard3 from "./cards/card3/HomeCard3";
import HomeCard4 from "./cards/card4/HomeCard4";
import HomeCard5 from "./cards/card5/HomeCard5";
import HomeCard0 from './cards/card0/HomeCard0';
import HomeCardCity from './cards/cardcity/HomeCardCity';

const HomeSwiperContent = () => {
    return (
        <div id={"home-swiper-content"}>
            <Swiper
                pagination={{ clickable: true }}
                autoplay={{ delay: 3000 }}>
                <SwiperSlide>
                    <HomeCardCity />
                </SwiperSlide>
                <SwiperSlide>
                    <HomeCard0 />
                </SwiperSlide>
                <SwiperSlide>
                    <HomeCard1 />
                </SwiperSlide>
                <SwiperSlide>
                    <HomeCard2 />
                </SwiperSlide>
                <SwiperSlide>
                    <HomeCard3 />
                </SwiperSlide>
                <SwiperSlide>
                    <HomeCard4 />
                </SwiperSlide>
                <SwiperSlide>
                    <HomeCard5 />
                </SwiperSlide>
            </Swiper>
        </div>
    )
};

export default HomeSwiperContent;
