import { initializeApp  } from 'firebase/app';
import { getDatabase } from "firebase/database";

const firebaseConfig = {
    apiKey: "AIzaSyBt05nYy6X32vxvdKvCvq0D0VzVIhz9Jh0",
    authDomain: "devmate-website.firebaseapp.com",
    databaseURL: "https://devmate-website-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "devmate-website",
    storageBucket: "devmate-website.appspot.com",
    messagingSenderId: "154651179772",
    appId: "1:154651179772:web:3ecc9c103f548a84c07579",
    measurementId: "G-D9JJFBJYT7"
};

const firebaseApp = initializeApp(firebaseConfig);
export const firebaseDB = getDatabase(firebaseApp);
