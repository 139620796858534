import {useEffect, useRef, useState} from "react";
import ClientsCompanyRow from "./ClientsCompanyRow";
import "./ClientsCompanyRowWrap.css";

function ClientsCompanyRowWrap({speed, images}) {
    const ref_row_1 = useRef(null);
    const ref_row_2 = useRef(null);
    const ref_row_3 = useRef(null);

    const [intervalId, setIntervalId] = useState(-1);

    useEffect(() => {
        setIntervalId(setInterval(() => {
            const row_1 = ref_row_1.current;
            const row_2 = ref_row_2.current;
            const row_3 = ref_row_3.current;

            if (row_1 && row_2) {
                const l_1 = parseFloat(row_1.style.left);
                const width = row_1.clientWidth;

                const left_1 = (isNaN(l_1) ? 0 : l_1) - speed;
                const left_2 = left_1 + width;
                const left_3 = left_2 + width;

                if (left_2 <= 0) {
                    row_1.style.left = 0 + "px";
                    row_2.style.left = width + "px";
                    row_3.style.left = width + width + "px";
                } else {
                    row_1.style.left = left_1 + 'px';
                    row_2.style.left = left_2 + 'px';
                    row_3.style.left = left_3 + 'px';
                }
            }
        }, 1000 / 60));
        return () => clearInterval(intervalId);
    }, []);

    return (
        <div className={"clients-company-row-wrap"}>
            <ClientsCompanyRow images={images} ref={ref_row_1}/>
            <ClientsCompanyRow images={images} ref={ref_row_2}/>
            <ClientsCompanyRow images={images} ref={ref_row_3}/>
        </div>
    );
}

export default ClientsCompanyRowWrap;
