import "./PortfolioDetailItem.css";
import PopupStore from "../../../../../../store/popupStore";

const popupStore = PopupStore.getInstance();

const PortfolioDetailItem = ({index, type, photo, title, description}) => {
    const onClick = () => {
        popupStore.currentKey = index;
        popupStore.currentType = type;
    };

    return (
        <div
            className={"portfolio-detail-item"}
            onClick={onClick}
        >
            <div
                className={"background"}
                style={{backgroundImage: `url(${photo})`}}
            />
            <div className={"blur"}/>
            <div className={"info"}>
                <div className={"info-background"}/>
                <header>{title}</header>
                <article>{description}</article>
            </div>
            <div
                className={"cover"}
                style={{
                    animationDelay: `${0.2 + 0.1 * index}s`
                }}/>
        </div>
    );
};

export default PortfolioDetailItem;
