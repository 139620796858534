import "./MainHeaderMenuLink.css";
import classNames from "classnames";

const MainHeaderMenuLink = ({active, link, children}) => {
    return (
        <a
            className={classNames("main-header-menu-link", {active})}
            href={link}
        >
            {children}
        </a>
    );
};

export default MainHeaderMenuLink;
