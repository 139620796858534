import {makeObservable, observable} from "mobx";

class PopupStore {
    currentType = null;
    currentKey = null;

    constructor() {
        makeObservable(this, {
            currentType: observable,
            currentKey: observable
        });
    }

    get shouldRender() {
        return !!this.currentType;
    }

    static _instance;

    static getInstance() {
        if (!this._instance) this._instance = new PopupStore();
        return this._instance;
    }
}

export default PopupStore;
