import classNames from "classnames";
import "./SelectBox.css";
import {useCallback, useEffect, useRef, useState} from "react";

const SelectBox = ({className, items, value, placeholder, onSelect}) => {
    const ref = useRef(null);
    const itemListRef = useRef(null);

    const [opened, setOpened] = useState(false);

    const handleClickOutside = useCallback(() => {
        setOpened(false)
    }, []);

    useEffect(() => {
        window.addEventListener('click', handleClickOutside);
        return () => window.removeEventListener('click', handleClickOutside);
    }, [handleClickOutside]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (ref.current && itemListRef.current) {
                const {left, bottom, right} = ref.current.getBoundingClientRect();

                itemListRef.current.style.top = bottom + 4 + "px";
                itemListRef.current.style.left = left + "px";
                itemListRef.current.style.width = right - left + "px";
            }
        }, 10);
        return () => clearInterval(interval);
    });

    return (
        <div
            ref={ref}
            className={classNames("select-box", className, {opened})}
            onClick={(event) => {
                setOpened(!opened);
                event.stopPropagation();
            }}
        >
            {
                !value &&
                <div className={"placeholder"}>{placeholder}</div>
            }
            {
                !!value &&
                <div className={"value"}>{value}</div>
            }
            <button>
                {
                    opened
                        ? (
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15 14L12 11L9 14" stroke="#0D0C0C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        )
                        : (
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9 11L12 14L15 11" stroke="#0D0C0C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        )
                }
            </button>
            <div
                className={"select-box-items"}
                ref={itemListRef}
            >
                <div className={"wrap"}>
                    {items.map((item, i) => (
                        <div
                            key={i}
                            className={"select-box-item"}
                            onClick={() => {
                                if (value !== item) {
                                    onSelect(item);
                                }
                            }}
                        >
                            {item}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
};

export default SelectBox;
