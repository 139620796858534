import "./MainHeaderMenu.css";
import MainHeaderMenuLink from "./link/MainHeaderMenuLink";
import MainHeaderMenuMultiLink from "./link/MainHeaderMenuMultiLink";
import PageSlideScrollStore from "../../../store/pageSlideScrollStore";
import { observer } from "mobx-react-lite";

const store = PageSlideScrollStore.getInstance();

const MainHeaderMenu = observer(() => {
    return (
        <div id={"main-header-menu"} className={"eng"}>
            <MainHeaderMenuLink link={"/#home"} active={store.isHome}>HOME</MainHeaderMenuLink>
            <MainHeaderMenuLink link={"/#about"} active={store.isAbout}>ABOUT</MainHeaderMenuLink>
            <MainHeaderMenuLink link={"/#services"} active={store.isServices}>SERVICES</MainHeaderMenuLink>
            <MainHeaderMenuLink link={"/#clients"} active={store.isClients}>CLIENTS</MainHeaderMenuLink>
            <MainHeaderMenuMultiLink
                active={store.isPortfolio}
                items={[
                    { name: 'ZEPETO', link: "/portfolio/detail/zepeto" },
                    { name: 'GATHER TOWN', link: "/portfolio/detail/gather-town" },
                    { name: 'ZEP', link: "/portfolio/detail/zep" },
                    { name: 'ETC', link: "/portfolio/detail/etc" }
                ]}
            >
                PORTFOLIO
            </MainHeaderMenuMultiLink>
            <MainHeaderMenuMultiLink
                active={store.isContact}
                items={[
                    { name: 'PROCESS', link: "/contact#contact-process" },
                    { name: 'FAQ', link: "/contact#contact-faq" },
                    { name: 'CONTACT', link: "/contact#contact-form" }
                ]}
            >
                CONTACT
            </MainHeaderMenuMultiLink>
            <MainHeaderMenuLink link={"/virtual-office"} active={store.isVirtualOffice}>VIRTUAL OFFICE</MainHeaderMenuLink>
        </div>
    );
});

export default MainHeaderMenu;
