import "./ClientsTitle.css";
import PageSlideTitle from "../../PageSlideTitle";

const ClientsTitle = () => {
    return (
        <div id={"clients-title"}>
            <PageSlideTitle>
                Make <span>Together</span><br/>
                With Devmate
            </PageSlideTitle>
            <p className={"description"}>
                지금까지 아래와 같은 파트너들이 데브메이트와 함께 업계 최고의 메타버스 컨텐츠를 만들었습니다.<br/>
                귀사의 아이디어도 우리와 같이 한없이 펼쳐보세요.
            </p>
        </div>
    );
};

export default ClientsTitle;
