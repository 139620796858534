import "./ServiceCard.css";
import "./ServiceCardNormal.css";
import "./ServiceCardDetail.css";
import classNames from "classnames";

const ServiceCard = ({active, no, title, icon, background, detailTitle, detailContent, detailSmallTitle, detailSmallContent}) => {
    return (
        <div className={classNames("service-card", {active})}>
            <div className={"wrap normal"}>
                <div className={"title"}>
                    <h2 className={"no"}>0{no}</h2>
                    <h2 className={"title"}>{title}</h2>
                    <div className={"icon"}>{icon}</div>
                </div>
            </div>
            <div className={"wrap detail"}>
                <div
                    className={"background"}
                    style={{backgroundImage: `url(${background})`}}
                />
                <div className={"blur"}/>
                <div className={"title"}>
                    <h2 className={"no"}>0{no}</h2>
                    <h2 className={"title"}>{title}</h2>
                    <div className={"icon"}>{icon}</div>
                </div>
                <div className={"detail normal"}>
                    <h3 className={"title"}>{detailTitle}</h3>
                    <p className={"content"}>{detailContent}</p>
                </div>
                <div className={"detail small"}>
                    <h3 className={"title"}>{detailSmallTitle}</h3>
                    <p className={"content"}>{detailSmallContent}</p>
                    <div className={"icon"}>{icon}</div>
                </div>
            </div>
        </div>
    );
};

export default ServiceCard;
