import "./AboutSlide.css";
import PageSlide from "../PageSlide";
import AboutTitle from "./title/AboutTitle";
import AboutContent from "./content/AboutContent";

const AboutSlide = () => {
    return (
        <PageSlide id={"about"}>
            <AboutTitle/>
            <AboutContent/>
        </PageSlide>
    );
}

export default AboutSlide;
