import {makeObservable, observable} from "mobx";

class MainDrawerStore {
    isOpened = false;

    constructor() {
        makeObservable(this, {
            isOpened: observable
        });
    }

    static _instance;

    static getInstance() {
        if (!this._instance) this._instance = new MainDrawerStore();
        return this._instance;
    }
}

export default MainDrawerStore;
