import "./ContactFAQItem.css";
import classNames from "classnames";
import {useState} from "react";

const ContactFAQItem = ({title, content}) => {
    const [opened, setOpened] = useState(false);

    const handleArticleClick = (e) => {
        e.stopPropagation();
        e.preventDefault();
    }

    return (
        <div className={classNames("contact-faq-item", {opened})} onClick={() => setOpened(!opened)}>
            <header>{title}</header>
            <article onClick={handleArticleClick}>{content}</article>
            <button onClick={() => setOpened(!opened)}>
                {
                    opened
                        ? (
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15 14L12 11L9 14" stroke="#311FFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        )
                        : (
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9 11L12 14L15 11" stroke="#0D0C0C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        )
                }
            </button>
        </div>
    );
};

export default ContactFAQItem;
