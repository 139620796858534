import "./AboutTitle.css";
import PageSlideTitle from "../../PageSlideTitle";
import {useSlideAnimating} from "../../../../../hook/useSlideAnimating";
import {observer} from "mobx-react-lite";
import classNames from "classnames";

const AboutTitle = observer(() => {
    const animating = useSlideAnimating('about');

    return (
        <div id={"about-title"} className={classNames({animating})}>
            <PageSlideTitle white>
                Change <span>your life</span><br/>
                with Devmate
            </PageSlideTitle>
            <div className={"cover"}/>
        </div>
    );
});

export default AboutTitle;
