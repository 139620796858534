import "./PortfolioItem.css";

const PortfolioItem = ({title, content}) => {
    return (
        <div className={"portfolio-item"}>
            <header>{title}</header>
            <article>{content}</article>
        </div>
    )
};

export default PortfolioItem;
