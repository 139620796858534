import classNames from "classnames";
import "./CloseButton.css";

const CloseButton = ({className, onClick}) => {
    return (
        <button className={classNames(className, "close-button")} onClick={onClick}>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16 4L10 10M10 10L16 16M10 10L4 4M10 10L4 16" stroke="#0D0C0C" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
            <div>닫기</div>
        </button>
    );
}

export default CloseButton;
