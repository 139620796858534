import classNames from "classnames";
import "./HomeCard.css";
import PopupStore from "../../../../../../../store/popupStore";

const popup = PopupStore.getInstance();

const HomeCard = ({className, itemKey, itemType, itemName, itemDesc}) => {
    const handleClick = () => {
        // popup.currentKey = itemKey;
        // popup.currentType = itemType;
    };

    return (
        <div
            className={classNames(className, "home-card")}
            onClick={handleClick}
        >
          <div className="home-card-title-wrap">
            <div className="home-card-title">{itemName}</div>
            <div className="home-card-desc">{itemDesc}</div>
          </div>
        </div>
    );
};

export default HomeCard;
