import "./ContactProcessItemList.css";
import ContactProcessItem from "./ContactProcessItem";
import ContactProcessItemPeriod from "./period/ContactProcessItemPeriod";

const ContactProcessItemList = () => {
    return (
        <div id={"contact-process-item-list"}>
            <div className={"list"}>
                <ContactProcessItem>
                    상담 접수 및<br/>통합 컨설팅
                </ContactProcessItem>
                <div className={"stepper"}/>
                <ContactProcessItem>
                    월드 구축 및<br/>아이템 제작
                </ContactProcessItem>
                <div className={"stepper"}/>
                <ContactProcessItem>
                    최종 검수 및<br/>시연
                </ContactProcessItem>
                <div className={"stepper"}/>
                <ContactProcessItem>
                    ZEPETO<br/>등록 심사
                </ContactProcessItem>
                <div className={"stepper"}/>
                <ContactProcessItem>
                    런칭 및<br/>결과 보고
                </ContactProcessItem>
            </div>
            <div className={"periods"}>
                <ContactProcessItemPeriod count={2}>
                    4-8 Weeks
                </ContactProcessItemPeriod>
                <ContactProcessItemPeriod count={2}>
                    2 Weeks
                </ContactProcessItemPeriod>
            </div>
        </div>
    );
};

export default ContactProcessItemList;
