import PageSlide from "../PageSlide";
import "./VirtualOfficeSlide.css";

const VirtualOfficeSlide = () => {
    return (
        <PageSlide id={"virtual-office"}>
            <div className={"coming-soon eng"}>
                COMING SOON
            </div>
        </PageSlide>
    );
};

export default VirtualOfficeSlide;
