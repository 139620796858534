import PortfolioPhotoBox from "../../common/photo/PortfolioPhotoBox";
import PortfolioPhoto from "../../common/photo/PortfolioPhoto";
import photo_1 from "./photo_1.png";
import photo_2 from "./photo_2.png";
import photo_3 from "./photo_3.png";

const GatherTownPortfolioPhotoBox = () => {
    return (
        <PortfolioPhotoBox rightward>
            <PortfolioPhoto
                title={"부산항만공사"}
                description={"ZEPETO 월드맵"}
                photo={photo_1}
                type={"sample"}
            />
            <PortfolioPhoto
                title={"부산항만공사"}
                description={"ZEPETO 월드맵"}
                photo={photo_2}
                type={"sample"}
            />
            <PortfolioPhoto
                title={"부산항만공사"}
                description={"ZEPETO 월드맵"}
                photo={photo_3}
                type={"sample"}
            />
        </PortfolioPhotoBox>
    )
};

export default GatherTownPortfolioPhotoBox;
