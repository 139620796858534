import {Swiper, SwiperSlide} from "swiper/react/swiper-react";
import ServiceCard1 from "./cards/ServiceCard1";
import ServiceCard2 from "./cards/ServiceCard2";
import ServiceCard3 from "./cards/ServiceCard3";
import ServiceCard4 from "./cards/ServiceCard4";
import "./ServicesSwiperContent.css";

const ServicesSwiperContent = () => {
    return (
        <div id={"services-swiper-content"}>
            <Swiper pagination={{clickable: true}}>
                <SwiperSlide>
                    <ServiceCard1 active/>
                </SwiperSlide>
                <SwiperSlide>
                    <ServiceCard2 active/>
                </SwiperSlide>
                <SwiperSlide>
                    <ServiceCard3 active/>
                </SwiperSlide>
                <SwiperSlide>
                    <ServiceCard4 active/>
                </SwiperSlide>
            </Swiper>
        </div>
    )
};

export default ServicesSwiperContent;
