import PageSlideTitle from "../../PageSlideTitle";

const ContactSlideTitle = () => {
    return (
        <div className={"content"}>
            <PageSlideTitle>
                We ready to<br/>
                create <span>your world</span>
            </PageSlideTitle>
        </div>
    );
}

export default ContactSlideTitle;
