import "./PortfolioPopupContentItem.css";
import classNames from "classnames";

const PortfolioPopupContentItem = ({className, title, children}) => {
    return (
        <div className={classNames(className, "portfolio-popup-content-item")}>
            <header>{title}</header>
            <article>{children}</article>
        </div>
    );
};

export default PortfolioPopupContentItem;
