import classNames from "classnames";
import "./PortfolioMoreButton.css";

const PortfolioMoreButton = ({className, icon, name, onClick}) => {
    return (
        <button
            className={classNames("portfolio-more-button", className)}
            onClick={onClick}
        >
            {icon}
            <div className={"title"}>모든 {name} 포트폴리오 보기</div>
        </button>
    );
};

export default PortfolioMoreButton;
