import { observer } from "mobx-react-lite";
import PortfolioSamplePopup from "./portfolio/sample/PortfolioSamplePopup";
import PopupStore from "../../../store/popupStore";

const popupStore = PopupStore.getInstance();

const PopupSwitcher = observer(() => {
  if (popupStore.shouldRender) {
    switch (popupStore.currentType) {
      case "sample":
        return <PortfolioSamplePopup />;
      case "gather":
        return (
          <PortfolioSamplePopup
            itemKey={popupStore.currentKey}
            type={popupStore.currentType}
          />
        );
      case "zep":
        return (
          <PortfolioSamplePopup
            itemKey={popupStore.currentKey}
            type={popupStore.currentType}
          />
        );
      case "zepeto":
        return (
          <PortfolioSamplePopup
            itemKey={popupStore.currentKey}
            type={popupStore.currentType}
          />
        );
      case "etc":
        return (
          <PortfolioSamplePopup
            itemKey={popupStore.currentKey}
            type={popupStore.currentType}
          />
        );
      default:
        return <></>;
    }
  }
  return null;
});

export default PopupSwitcher;
