import PageSlide from "../../PageSlide";
import {Link} from "react-router-dom";
import "./PortfolioDetailSlide.css";
import PageSlideTitle from "../../PageSlideTitle";
import classNames from "classnames";

const PortfolioDetailSlide = ({type, children}) => {
    return (
        <PageSlide id={"portfolio-detail"}>
            <div className={"content"}>
                <PageSlideTitle className={"title"}>
                    Devmate <span>Portfolios</span>
                </PageSlideTitle>
                <div className={"tab-box"}>
                    <Link
                        className={classNames("tab eng", {current: type === "zepeto"})}
                        to={"/portfolio/detail/zepeto"}
                    >
                        ZEPETO
                    </Link>
                    <Link
                        className={classNames("tab eng", {current: type === "gather-town"})}
                        to={"/portfolio/detail/gather-town"}
                    >
                        Gather Town
                    </Link>
                    <Link
                        className={classNames("tab eng", {current: type === "zep"})}
                        to={"/portfolio/detail/zep"}
                    >
                        ZEP
                    </Link>
                    <Link
                        className={classNames("tab eng", {current: type === "etc"})}
                        to={"/portfolio/detail/etc"}
                    >
                        ETC
                    </Link>
                </div>
                <div className={"items"}>
                    {children}
                </div>
            </div>
        </PageSlide>
    );
};

export default PortfolioDetailSlide;
