import "./MainHeader.css";
import MainHeaderLogo from "./logo/MainHeaderLogo";
import MainHeaderMenu from "./menu/MainHeaderMenu";
import MainHeaderDrawerOpener from "./drawerOpener/MainHeaderDrawerOpener";

const MainHeader = () => {
    return (
        <header id={"main-header"}>
            <MainHeaderLogo/>
            <MainHeaderMenu/>
            <MainHeaderDrawerOpener/>
        </header>
    );
}

export default MainHeader;
