import PageSlide from "../PageSlide";
import "./ContactSlide.css";
import ContactSlideTitle from "./title/ContactSlideTitle";
import ContactProcess from "./process/ContactProcess";
import ContactFAQ from "./faq/ContactFAQ";
import ContactForm from "./form/ContactForm";

const ContactSlide = () => {
    return (
        <PageSlide id={"contact"}>
            <ContactSlideTitle/>
            <ContactProcess/>
            <ContactFAQ/>
            <ContactForm/>
        </PageSlide>
    );
};

export default ContactSlide;
