import PortfolioItem from "../../common/item/PortfolioItem";

const ZepetoPortfolioItems = () => {
    return (
        <>
            <PortfolioItem
                title={"월드맵 기획 / 구성"}
                content={"기업 브랜드의 톤 앤 매너에 맞게 최적화된 가상공간을 기획합니다."}
            />
            <PortfolioItem
                title={"3D 아이템 브랜딩 디자인"}
                content={
                    <>
                        제페토 아바타가 착용 가능한 3D 아이템을 기획 / 디자인합니다.<br/>
                        브랜드 마케팅에 효과적인 아이템 제작이 가능하며 제페토 캐릭터를<br/>
                        실제로 운영하여 홍보까지 도와드립니다.
                    </>
                }
            />
            <PortfolioItem
                title={"오퍼레이터 파견 및 기술지원"}
                content={
                    <>
                        행사 진행시 오퍼레이터를 파견하여 원활한 행사진행이 가능하도록<br/>
                        기술지원팀을 파견해드립니다.
                    </>
                }
            />
            <PortfolioItem
                title={"제페토 비디오 제작"}
                content={
                    <>
                        제페토로 브랜드와 관련된 다양한 비디오를 제작해드립니다.<br/>
                        뮤직비디오, 홍보영상 등 브랜드 홍보를 제페토로 시작해보세요.
                    </>
                }
            />
        </>
    );
};

export default ZepetoPortfolioItems;
