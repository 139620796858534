import "./Popup.css";
import classNames from "classnames";
import CloseButton from "../../../common/button/close/CloseButton";
import PopupStore from "../../../store/popupStore";
import {useEffect, useState} from "react";

const popupStore = PopupStore.getInstance();

const Popup = ({id, className, children}) => {
    const [mounted, setMounted] = useState(false);

    const onClick = () => {
        setMounted(false);
        setTimeout(() => {
            popupStore.currentType = null;
        }, 300);
    };

    useEffect(() => {
        setMounted(true);
    }, []);

    return (
        <div className={classNames("popup-wrap", {mounted})}>
            <div id={id} className={classNames(className, "popup")}>
                <div className={"popup-content"}>
                    <CloseButton className={"close"} onClick={onClick}/>
                    {children}
                </div>
            </div>
        </div>
    );
};

export default Popup;
