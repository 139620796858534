import "./PageSlideTitle.css";
import classNames from "classnames";

const PageSlideTitle = ({className, white, children}) => {
    return (
        <h1 className={classNames("page-slide-title eng", className, {white})}>
            {children}
        </h1>
    );
};

export default PageSlideTitle;
