import {useCallback} from "react";
import MainDrawerStore from "../../drawer/mainDrawerStore";
import "./MainHeaderDrawerOpener.css";

const drawerStore = MainDrawerStore.getInstance();

const MainHeaderDrawerOpener = () => {
    const onClick = useCallback(() => {
        drawerStore.isOpened = true;
    }, []);

    return (
        <button id={"main-header-drawer-opener"} onClick={onClick}>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 6L18 6M2 10H18M2 14L18 14" stroke="#0D0C0C" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </button>
    )
};

export default MainHeaderDrawerOpener;
