import PageSlide from "../../PageSlide";
import "./PortfolioSlide.css";

const PortfolioSlide = ({id, children}) => {
    return (
        <PageSlide id={id} className={"portfolio-slide"}>
            <div className={"content"}>
                {children}
            </div>
        </PageSlide>
    );
};

export default PortfolioSlide;
