import './App.css';
import MainHeader from "./header/MainHeader";
import MainContent from "./content/MainContent";
import Sidebar from "./sidebar/Sidebar";
import PopupSwitcher from "./content/popup/PopupSwitcher";
import MainDrawerWrap from "./drawer/MainDrawerWrap";
import SwiperCore, {Autoplay, Pagination} from "swiper";
import "swiper/swiper.min.css";
import "swiper/modules/pagination/pagination.min.css"
import "swiper/modules/autoplay/autoplay.min.css"

SwiperCore.use([Pagination, Autoplay]);

const App = () => {
    return (
        <div id={"app"}>
            <MainHeader/>
            <MainContent/>
            <Sidebar/>
            <PopupSwitcher/>
            <MainDrawerWrap/>
        </div>
    );
}

export default App;
