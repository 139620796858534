import {observer} from "mobx-react-lite";
import MainDrawerStore from "./mainDrawerStore";
import MainDrawer from "./MainDrawer";

const drawerStore = MainDrawerStore.getInstance();

const MainDrawerWrap = observer(() => {
    if (drawerStore.isOpened) {
        return <MainDrawer/>
    }
    return null;
});

export default MainDrawerWrap;
