import "./vllage.css";

const communityText = `
제1조 (목적)
본 약관은 주식회사 데브메이트(이하 "회사"라 합니다)가 제공하는 브이리지 커뮤니티 및 브이리지 커뮤니티 관련 제반 서비스(이하 "서비스"라 합니다)의 이용과 관련하여 회사와 회원과의 권리, 의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.

제2조 (회원의 정의)
① 회원이란 회사가 제공하는 서비스에 접속하여 본 약관에 따라 회사의 이용절차에 동의하고 회사가 제공하는 서비스를 이용하는 이용자를 말합니다.

제3조 (회원 가입)
① 회원이 되고자 하는 자는 회사가 정한 가입 양식에 따라 회원정보를 기입하고 "동의", "확인", "로그인하기" 등의 버튼을 누르는 방법으로 회원 가입을 신청합니다.
② 회사는 제1항과 같이 회원으로 가입할 것을 신청한 자가 다음 각 호에 해당하지 않는 한 신청한 자를 회원으로 등록합니다.
     1. 등록 내용에 허위, 기재누락, 오기가 있는 경우
     2. 제6조 제2항에 해당하는 회원 자격 제한 및 정지, 상실을 한 경험이 있었던 경우
     3. 기타 회원으로 등록하는 것이 회사의 서비스 운영 및 기술상 현저히 지장이 있다고 판단되는 경우
③ 회원가입계약의 성립시기는 회사의 승낙이 가입신청자에게 도달한 시점으로 합니다.
④ 회원은 제1항의 회원정보 기재 내용에 변경이 발생한 경우, 즉시 변경사항을 정정하여 기재하여야 합니다.

제4조 (서비스의 제공 및 변경)
① 회사는 회원에게 아래와 같은 서비스를 제공합니다.
     1. 커뮤니티 서비스 (게시판 등)
     2. 검색 서비스
     3. 기타 회사가 자체 개발하거나 다른 회사와의 협력계약 등을 통해 회원들에게 제공할 일체의 서비스
② 회사는 서비스의 내용 및 제공일자를 제7조 제2항에서 정한 방법으로 회원에게 통지하고, 제1항에 정한 서비스를 변경하여 제공할 수 있습니다.

제5조 (서비스의 중단)
① 회사는 컴퓨터 등 정보통신설비의 보수점검·교체 및 고장, 통신의 두절 등의 사유가 발생한 경우에는 서비스의 제공을 일시적으로 중단할 수 있고, 새로운 서비스로의 교체, 기타 회사가 적절하다고 판단하는 사유에 기하여 현재 제공되는 서비스를 완전히 중단할 수 있습니다.
② 제1항에 의한 서비스 중단의 경우에 회사는 제7조 제2항에서 정한 방법으로 회원에게 통지합니다. 다만, 회사가 통제할 수 없는 사유로 인한 서비스의 중단(시스템 관리자의 고의, 과실이 없는 디스크 장애, 시스템 다운 등)으로 인하여 사전 통지가 불가능한 경우에는 그러하지 아니합니다.

제6조 (회원 탈퇴 및 자격 상실 등)
① 회원은 회사에 언제든지 자신의 회원 등록 말소(회원 탈퇴)를 요청할 수 있으며 회사는 위 요청을 받은 즉시 해당 회원의 회원 등록 말소를 위한 절차를 밟습니다.
② 탈퇴할 경우, 커뮤니티 서비스, 기타 공용 게시판 등에 등록된 게시물은 자동 삭제되지 않습니다.
③ 회원이 다음 각 호의 사유에 해당하는 경우, 회사는 회원의 회원자격을 적절한 방법으로 제한 및 정지, 상실시킬 수 있습니다.
     1. 가입 신청 시에 허위 내용을 등록한 경우
     2. 다른 사람의 서비스 이용을 방해하거나 그 정보를 도용하는 등 전자거래질서를 위협하는 경우
     3. 서비스를 이용하여 법령과 본 약관이 금지하거나 공서양속에 반하는 행위를 하는 경우
     4. 본 약관 및 관계법령을 위반한 경우
④ 회사가 회원의 회원자격을 상실시키기로 결정한 경우에는 회원등록을 말소합니다.
⑤ 이용자가 본 약관에 의해서 회원 가입 후 서비스를 이용하는 도중, 연속하여 1년 동안 서비스를 이용하기 위해 로그인 기록이 없는 경우, 회사는 회원의 회원자격을 상실시킬 수 있습니다.

제7조 (회원에 대한 통지)
① 회사가 특정 회원에게 서비스에 관한 통지를 하는 경우 회원정보에 등록된 메일주소를 사용할 수 있습니다.
② 회사가 불특정다수 회원에 대한 통지를 하는 경우 7일 이상 공지사항 게시판에 게시함으로써 개별 통지에 갈음할 수 있습니다.

제8조 (회원의 개인정보)
회사는 서비스를 제공하기 위하여 관련 법령의 규정에 따라 회원으로부터 필요한 개인정보를 수집합니다. (개인정보에 대한 개별 항목은 개인정보취급방침에서 고지)

제9조 (회사의 의무)
① 회사는 법령과 본 약관이 금지하거나 공서양속에 반하는 행위를 하지 않으며 본 약관이 정하는 바에 따라 지속적이고, 안정적으로 서비스를 제공하기 위해서 노력합니다.
② 회사는 회원이 안전하고 편리하게 서비스를 이용할 수 있도록 시스템을 구축합니다.
③ 회사는 회원이 서비스를 이용함에 있어 회원에게 손해를 배상할 책임이 없습니다.

제10조 (회원의 ID 및 비밀번호에 대한 의무)
① 회사가 관계법령, "개인정보보호정책"에 의해서 그 책임을 지는 경우를 제외하고, 자신의 ID와 비밀번호에 관한 관리책임은 각 회원에게 있습니다.
② 회원은 자신의 ID 및 비밀번호를 제3자에게 이용하게 해서는 안됩니다.
③ 회원은 자신의 ID 및 비밀번호를 도난당하거나 제3자가 사용하고 있음을 인지한 경우에는 바로 회사에 통보하고 회사의 안내가 있는 경우에는 그에 따라야 합니다.

제11조 (회원의 의무)
① 회원은 다음 각 호의 행위를 하여서는 안 됩니다.
1. 회사 및 제3자의 지적재산권, 저작권을 침해하거나 회사의 권리와 업무 또는 제3자의 권리와 활동을 방해하는 행위
2. 관련 법령에 의하여 전송 또는 게시가 금지되는 정보(컴퓨터 프로그램, 불법 유해 정보 등)의 게시 또는 전송하는 행위
3. 회사의 직원 또는 서비스의 관리자를 가장하거나 타인의 명의를 도용하여 정보를 게시, 전송하는 행위
4. 컴퓨터 소프트웨어, 하드웨어, 전기통신 장비의 정상적인 가동을 방해, 파괴할 목적으로 고안된 소프트웨어 바이러스, 기타 다른 컴퓨터 코드, 파일, 프로그램을 포함하고 있는 자료를 게시하거나 전송하는 행위
5. 스토킹(stalking) 등 다른 회원을 괴롭히는 행위
6. 다른 회원 및 제3자에 대한 개인정보를 그 동의 없이 수집, 저장, 공개하는 등의 개인정보 침해 행위 및 명예훼손을 하는 행위
7. 불특정 다수의 자를 대상으로 하여 광고 또는 선전을 게시하거나 음란물, 청소년 유해매체물, 불법촬영물 등을 게시하는 행위
8. 커뮤니티 이용 통합 규정 (https://v-llage.com/posts/1987) 에 어긋나는 행위
② 제1항에 해당하는 행위를 한 회원이 있을 경우 회사는 본 약관 제6조 제3항에서 정한 바에 따라 회원의 서비스 이용 자격을 적절한 방법으로 제한 및 정지, 상실시킬 수 있습니다.
③ 회원은 그 귀책사유로 인하여 회사나 다른 회원이 입은 손해를 배상할 책임이 있습니다.


제12조 (공개 게시물의 삭제 또는 이용 제한)
① 회원의 공개 게시물의 내용이 다음 각 호에 해당하는 경우 회사는 해당 공개 게시물에 대한 접근을 임시적으로 차단하는 조치를 취할 수 있고, 각 호의 동일 사례가 반복되는 경우 해당 게시물을 삭제 또는 해당 회원의 이용 자격을 제한, 정지 또는 상실시킬 수 있습니다.
1. 다른 회원 또는 제3자를 비방하거나 중상모략으로 명예를 손상시키는 내용
2. 음란물, 청소년 유해매체물, 불법촬영물, 욕설 등 공서양속에 위반되는 내용의 정보, 문장, 도형 등을 유포하는 내용
3. 범죄행위, 불법 유해 정보와 관련이 있다고 판단되는 내용
4. 다른 회원 또는 제3자의 저작권 등 기타 권리를 침해하는 내용
5. 종교적, 정치적 분쟁을 야기하는 내용으로서, 이러한 분쟁으로 인하여 회사의 업무가 방해되거나 방해되리라고 판단되는 경우
6. 타인의 개인정보, 사생활을 침해하거나 명예훼손을 시키는 경우
7. 동일한 내용을 중복하여 다수 게시하는 등 게시의 목적에 어긋나는 경우
8. 불필요하거나 승인되지 않은 광고, 판촉물을 게재하는 경우
9. 커뮤니티 이용 통합 규정 (https://v-llage.com/posts/1987) 에 어긋나는 행위
10. 기타 게시판 관리자의 판단에 의하여 부적절하다고 판단되는 경우
② 회원의 공개 게시물로 인한 법률상 이익 침해를 근거로, 다른 회원 또는 제3자가 회원 또는 회사를 대상으로 하여 민형사상의 법적 조치(예: 고소, 가처분 신청, 손해배상청구소송)를 취하는 동시에 법적 조치와 관련된 게시물의 삭제를 요청해오는 경우, 회사는 동 법적 조치의 결과(예: 검찰의 기소, 법원의 가처분 결정, 손해배상 판결)가 있을 때까지 관련 게시물에 대한 접근을 잠정적으로 제한할 수 있습니다.

제13조 (저작권의 귀속 및 게시물의 이용)
① 회사가 작성한 저작물에 대한 저작권, 기타 지적재산권은 회사에 귀속합니다.
② 회원은 회사가 제공하는 서비스를 이용함으로써 얻은 정보를 회사의 사전 승낙 없이 복제, 전송, 출판, 배포, 방송, 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안 됩니다.
③ 회원이 서비스 내에 게시한 게시물의 저작권은 게시한 회원에게 귀속됩니다. 단, 회사는 서비스의 운영, 전시, 전송, 배포, 홍보의 목적으로 회원의 별도의 허락 없이 무상으로 '저작권법'에 규정하는 공정한 관행에 합치되게 합리적인 범위 내에서 다음과 같이 회원가 등록한 게시물을 사용할 수 있습니다.
1. 서비스 내에서 회원 게시물의 복제, 수정, 개조, 전시, 전송, 배포 및 저작물성을 해치지 않는 범위 내에서의 편집 저작물 작성
2. 미디어, 통신사 등 서비스 제휴 파트너에게 회원의 게시물 내용을 제공, 전시 혹은 홍보하게 하는 것. 단, 이 경우 회사는 별도의 동의 없이 회원의 개인정보를 제공하지 않습니다.
3. 회사는 전 항 이외의 방법으로 회원의 게시물을 이용하고자 하는 경우, 전화, 팩스, 이메일 등의 방법을 통해 사전에 회원의 동의를 얻어야 합니다.

제14조 (광고 게재 및 광고주와의 거래)
① 회사가 회원에게 서비스를 제공할 수 있는 서비스 투자기반의 일부는 광고 게재를 통한 수익으로부터 나옵니다. 회원은 회원이 등록한 게시물의 내용을 활용한 광고 게재 및 기타 서비스상에 노출되는 광고 게재에 대해 동의합니다.
② 회사는 서비스상에 게재되어 있거나 서비스를 통한 광고주의 판촉활동에 회원가 참여하거나 교신 또는 거래를 함으로써 발생하는 손실과 손해에 대해 책임을 지지 않습니다.

제15조 (약관의 개정)
① 회사는 약관의규제등에관한법률, 전자거래기본법, 전자서명법, 정보통신망이용촉진등에관한법률 등 관련법을 위배하지 않는 범위에서 본 약관을 개정할 수 있습니다.
② 다만, 개정 내용이 회원에게 불리할 경우에는 적용일자 5일 이전부터 적용일자 전일까지 공지합니다. 
③ 회원은 변경된 약관에 대해 거부할 권리가 있습니다. 회원은 변경된 약관이 공지된 후 14일 이내에 거부의사를 표명할 수 있습니다. 회원이 거부하는 경우 회사는 당해 회원과의 계약을 해지할 수 있습니다. 만약 회원이 변경된 약관이 공지된 후 14일 이내에 거부의사를 표시하지 않는 경우에는 동의하는 것으로 간주합니다.

제16조 (재판관할)
회사와 회원간에 발생한 서비스 이용에 관한 분쟁에 대하여는 대한민국 법을 적용하며, 본 분쟁으로 인한 소는 민사소송법상의 관할을 가지는 대한민국의 법원에 제기합니다.

부칙
1. 이 약관은 2022년 12월 23일 부터 적용됩니다.
`;

const shopText = `
제1조(목적) 이 약관은 주식회사 데브메이트(전자상거래 사업자)가 운영하는 브이리지 상점(이하 “몰”이라 한다)에서 제공하는 인터넷 관련 서비스(이하 “서비스”라 한다)를 이용함에 있어 사이버 몰과 이용자의 권리․의무 및 책임사항을 규정함을 목적으로 합니다.
 
  ※「PC통신, 무선 등을 이용하는 전자상거래에 대해서도 그 성질에 반하지 않는 한 이 약관을 준용합니다.」
 
제2조(정의)
 
  ① “몰”이란 주식회사 데브메이트가 재화 또는 용역(이하 “재화 등”이라 함)을 이용자에게 제공하기 위하여 컴퓨터 등 정보통신설비를 이용하여 재화 등을 거래할 수 있도록 설정한 가상의 영업장을 말하며, 아울러 사이버몰을 운영하는 사업자의 의미로도 사용합니다.
 
  ② “이용자”란 “몰”에 접속하여 이 약관에 따라 “몰”이 제공하는 서비스를 받는 회원 및 비회원을 말합니다.
 
  ③ ‘회원’이라 함은 “몰”에 회원등록을 한 자로서, 계속적으로 “몰”이 제공하는 서비스를 이용할 수 있는 자를 말합니다.
 
  ④ ‘비회원’이라 함은 회원에 가입하지 않고 “몰”이 제공하는 서비스를 이용하는 자를 말합니다.
 
제3조 (약관 등의 명시와 설명 및 개정)
 
  ① “몰”은 이 약관의 내용과 상호 및 대표자 성명, 영업소 소재지 주소(소비자의 불만을 처리할 수 있는 곳의 주소를 포함), 전화번호․모사전송번호․전자우편주소, 사업자등록번호, 통신판매업 신고번호, 개인정보관리책임자등을 이용자가 쉽게 알 수 있도록 00 사이버몰의 초기 서비스화면(전면)에 게시합니다. 다만, 약관의 내용은 이용자가 연결화면을 통하여 볼 수 있도록 할 수 있습니다.
 
  ② “몰은 이용자가 약관에 동의하기에 앞서 약관에 정하여져 있는 내용 중 청약철회․배송책임․환불조건 등과 같은 중요한 내용을 이용자가 이해할 수 있도록 별도의 연결화면 또는 팝업화면 등을 제공하여 이용자의 확인을 구하여야 합니다.
 
  ③ “몰”은 「전자상거래 등에서의 소비자보호에 관한 법률」, 「약관의 규제에 관한 법률」, 「전자문서 및 전자거래기본법」, 「전자금융거래법」, 「전자서명법」, 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」, 「방문판매 등에 관한 법률」, 「소비자기본법」 등 관련 법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.
 
  ④ “몰”이 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 몰의 초기화면에 그 적용일자 7일 이전부터 적용일자 전일까지 공지합니다. 다만, 이용자에게 불리하게 약관내용을 변경하는 경우에는 최소한 30일 이상의 사전 유예기간을 두고 공지합니다.  이 경우 "몰“은 개정 전 내용과 개정 후 내용을 명확하게 비교하여 이용자가 알기 쉽도록 표시합니다.
 
  ⑤ “몰”이 약관을 개정할 경우에는 그 개정약관은 그 적용일자 이후에 체결되는 계약에만 적용되고 그 이전에 이미 체결된 계약에 대해서는 개정 전의 약관조항이 그대로 적용됩니다. 다만 이미 계약을 체결한 이용자가 개정약관 조항의 적용을 받기를 원하는 뜻을 제3항에 의한 개정약관의 공지기간 내에 “몰”에 송신하여 “몰”의 동의를 받은 경우에는 개정약관 조항이 적용됩니다.
 
  ⑥ 이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 전자상거래 등에서의 소비자보호에 관한 법률, 약관의 규제 등에 관한 법률, 공정거래위원회가 정하는 전자상거래 등에서의 소비자 보호지침 및 관계법령 또는 상관례에 따릅니다.
 
제4조(서비스의 제공 및 변경)
 
  ① “몰”은 다음과 같은 업무를 수행합니다.
 
    1. 재화 또는 용역에 대한 정보 제공 및 구매계약의 체결
    2. 구매계약이 체결된 재화 또는 용역의 배송
    3. 기타 “몰”이 정하는 업무
 
  ② “몰”은 재화 또는 용역의 품절 또는 기술적 사양의 변경 등의 경우에는 장차 체결되는 계약에 의해 제공할 재화 또는 용역의 내용을 변경할 수 있습니다. 이 경우에는 변경된 재화 또는 용역의 내용 및 제공일자를 명시하여 현재의 재화 또는 용역의 내용을 게시한 곳에 즉시 공지합니다.
 
  ③ “몰”이 제공하기로 이용자와 계약을 체결한 서비스의 내용을 재화등의 품절 또는 기술적 사양의 변경 등의 사유로 변경할 경우에는 그 사유를 이용자에게 통지 가능한 주소로 즉시 통지합니다.
 
  ④ 전항의 경우 “몰”은 이로 인하여 이용자가 입은 손해를 배상합니다. 다만, “몰”이 고의 또는 과실이 없음을 입증하는 경우에는 그러하지 아니합니다.
 
제5조(서비스의 중단)
 
  ① “몰”은 컴퓨터 등 정보통신설비의 보수점검․교체 및 고장, 통신의 두절 등의 사유가 발생한 경우에는 서비스의 제공을 일시적으로 중단할 수 있습니다.
 
  ② “몰”은 제1항의 사유로 서비스의 제공이 일시적으로 중단됨으로 인하여 이용자 또는 제3자가 입은 손해에 대하여 배상합니다. 단, “몰”이 고의 또는 과실이 없음을 입증하는 경우에는 그러하지 아니합니다.
 
  ③ 사업종목의 전환, 사업의 포기, 업체 간의 통합 등의 이유로 서비스를 제공할 수 없게 되는 경우에는 “몰”은 제8조에 정한 방법으로 이용자에게 통지하고 당초 “몰”에서 제시한 조건에 따라 소비자에게 보상합니다. 다만, “몰”이 보상기준 등을 고지하지 아니한 경우에는 이용자들의 마일리지 또는 적립금 등을 “몰”에서 통용되는 통화가치에 상응하는 현물 또는 현금으로 이용자에게 지급합니다.
 
제6조(회원가입)
 
  ① 이용자는 “몰”이 정한 가입 양식에 따라 회원정보를 기입한 후 이 약관에 동의한다는 의사표시를 함으로서 회원가입을 신청합니다.
 
  ② “몰”은 제1항과 같이 회원으로 가입할 것을 신청한 이용자 중 다음 각 호에 해당하지 않는 한 회원으로 등록합니다.
 
    1. 가입신청자가 이 약관 제7조제3항에 의하여 이전에 회원자격을 상실한 적이 있는 경우, 다만 제7조제3항에 의한 회원자격 상실 후 3년이 경과한 자로서 “몰”의 회원재가입 승낙을 얻은 경우에는 예외로 한다.
    2. 등록 내용에 허위, 기재누락, 오기가 있는 경우
    3. 기타 회원으로 등록하는 것이 “몰”의 기술상 현저히 지장이 있다고 판단되는 경우
 
  ③ 회원가입계약의 성립 시기는 “몰”의 승낙이 회원에게 도달한 시점으로 합니다.
 
  ④ 회원은 회원가입 시 등록한 사항에 변경이 있는 경우, 상당한 기간 이내에 “몰”에 대하여 회원정보 수정 등의 방법으로 그 변경사항을 알려야 합니다.
 
제7조(회원 탈퇴 및 자격 상실 등)
 
  ① 회원은 “몰”에 언제든지 탈퇴를 요청할 수 있으며 “몰”은 즉시 회원탈퇴를 처리합니다.
 
  ② 회원이 다음 각 호의 사유에 해당하는 경우, “몰”은 회원자격을 제한 및 정지시킬 수 있습니다.
 
    1. 가입 신청 시에 허위 내용을 등록한 경우
    2. “몰”을 이용하여 구입한 재화 등의 대금, 기타 “몰”이용에 관련하여 회원이 부담하는 채무를 기일에 지급하지 않는 경우
    3. 다른 사람의 “몰” 이용을 방해하거나 그 정보를 도용하는 등 전자상거래 질서를 위협하는 경우
    4. “몰”을 이용하여 법령 또는 이 약관이 금지하거나 공서양속에 반하는 행위를 하는 경우
 
  ③ “몰”이 회원 자격을 제한․정지 시킨 후, 동일한 행위가 2회 이상 반복되거나 30일 이내에 그 사유가 시정되지 아니하는 경우 “몰”은 회원자격을 상실시킬 수 있습니다.
 
  ④ “몰”이 회원자격을 상실시키는 경우에는 회원등록을 말소합니다. 이 경우 회원에게 이를 통지하고, 회원등록 말소 전에 최소한 30일 이상의 기간을 정하여 소명할 기회를 부여합니다.
 
제8조(회원에 대한 통지)
 
  ① “몰”이 회원에 대한 통지를 하는 경우, 회원이 “몰”과 미리 약정하여 지정한 전자우편 주소로 할 수 있습니다.
 
  ② “몰”은 불특정다수 회원에 대한 통지의 경우 1주일이상 “몰” 게시판에 게시함으로서 개별 통지에 갈음할 수 있습니다. 다만, 회원 본인의 거래와 관련하여 중대한 영향을 미치는 사항에 대하여는 개별통지를 합니다.
 
제9조(구매신청 및 개인정보 제공 동의 등)
 
  ① “몰”이용자는 “몰”상에서 다음 또는 이와 유사한 방법에 의하여 구매를 신청하며, “몰”은 이용자가 구매신청을 함에 있어서 다음의 각 내용을 알기 쉽게 제공하여야 합니다.
      1. 재화 등의 검색 및 선택
      2. 받는 사람의 성명, 주소, 전화번호, 전자우편주소(또는 이동전화번호) 등의 입력
      3. 약관내용, 청약철회권이 제한되는 서비스, 배송료․설치비 등의 비용부담과 관련한 내용에 대한 확인
      4. 이 약관에 동의하고 위 3.호의 사항을 확인하거나 거부하는 표시
         (예, 마우스 클릭)
      5. 재화등의 구매신청 및 이에 관한 확인 또는 “몰”의 확인에 대한 동의
      6. 결제방법의 선택
 
  ② “몰”이 제3자에게 구매자 개인정보를 제공할 필요가 있는 경우 1) 개인정보를 제공받는 자, 2)개인정보를 제공받는 자의 개인정보 이용목적, 3) 제공하는 개인정보의 항목, 4) 개인정보를 제공받는 자의 개인정보 보유 및 이용기간을 구매자에게 알리고 동의를 받아야 합니다. (동의를 받은 사항이 변경되는 경우에도 같습니다.)
 
 
  ③ “몰”이 제3자에게 구매자의 개인정보를 취급할 수 있도록 업무를 위탁하는 경우에는 1) 개인정보 취급위탁을 받는 자, 2) 개인정보 취급위탁을 하는 업무의 내용을 구매자에게 알리고 동의를 받아야 합니다. (동의를 받은 사항이 변경되는 경우에도 같습니다.) 다만, 서비스제공에 관한 계약이행을 위해 필요하고 구매자의 편의증진과 관련된 경우에는 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」에서 정하고 있는 방법으로 개인정보 취급방침을 통해 알림으로써 고지절차와 동의절차를 거치지 않아도 됩니다.
 
 
제10조 (계약의 성립)
 
  ①  “몰”은 제9조와 같은 구매신청에 대하여 다음 각 호에 해당하면 승낙하지 않을 수 있습니다. 다만, 미성년자와 계약을 체결하는 경우에는 법정대리인의 동의를 얻지 못하면 미성년자 본인 또는 법정대리인이 계약을 취소할 수 있다는 내용을 고지하여야 합니다.
 
    1. 신청 내용에 허위, 기재누락, 오기가 있는 경우
    2. 미성년자가 담배, 주류 등 청소년보호법에서 금지하는 재화 및 용역을 구매하는 경우
    3. 기타 구매신청에 승낙하는 것이 “몰” 기술상 현저히 지장이 있다고 판단하는 경우
 
  ② “몰”의 승낙이 제12조제1항의 수신확인통지형태로 이용자에게 도달한 시점에 계약이 성립한 것으로 봅니다.
 
  ③ “몰”의 승낙의 의사표시에는 이용자의 구매 신청에 대한 확인 및 판매가능 여부, 구매신청의 정정 취소 등에 관한 정보 등을 포함하여야 합니다.
 
제11조(지급방법) “몰”에서 구매한 재화 또는 용역에 대한 대금지급방법은 다음 각 호의 방법중 가용한 방법으로 할 수 있습니다. 단, “몰”은 이용자의 지급방법에 대하여 재화 등의 대금에 어떠한 명목의 수수료도  추가하여 징수할 수 없습니다.
 
    1. 폰뱅킹, 인터넷뱅킹, 메일 뱅킹 등의 각종 계좌이체
    2. 선불카드, 직불카드, 신용카드 등의 각종 카드 결제
    3. 온라인무통장입금
    4. 전자화폐에 의한 결제
    5. 수령 시 대금지급
    6. 마일리지 등 “몰”이 지급한 포인트에 의한 결제
    7. “몰”과 계약을 맺었거나 “몰”이 인정한 상품권에 의한 결제  
    8. 기타 전자적 지급 방법에 의한 대금 지급 등
 
제12조(수신확인통지․구매신청 변경 및 취소)
 
  ① “몰”은 이용자의 구매신청이 있는 경우 이용자에게 수신확인통지를 합니다.
 
  ② 수신확인통지를 받은 이용자는 의사표시의 불일치 등이 있는 경우에는 수신확인통지를 받은 후 즉시 구매신청 변경 및 취소를 요청할 수 있고 “몰”은 배송 전에 이용자의 요청이 있는 경우에는 지체 없이 그 요청에 따라 처리하여야 합니다. 다만 이미 대금을 지불한 경우에는 제15조의 청약철회 등에 관한 규정에 따릅니다.
 
제13조(재화 등의 공급)
 
  ① “몰”은 이용자와 재화 등의 공급시기에 관하여 별도의 약정이 없는 이상, 이용자가 청약을 한 날부터 7일 이내에 재화 등을 배송할 수 있도록 주문제작, 포장 등 기타의 필요한 조치를 취합니다. 다만, “몰”이 이미 재화 등의 대금의 전부 또는 일부를 받은 경우에는 대금의 전부 또는 일부를 받은 날부터 3영업일 이내에 조치를 취합니다.  이때 “몰”은 이용자가 재화 등의 공급 절차 및 진행 사항을 확인할 수 있도록 적절한 조치를 합니다.
 
  ② “몰”은 이용자가 구매한 재화에 대해 배송수단, 수단별 배송비용 부담자, 수단별 배송기간 등을 명시합니다. 만약 “몰”이 약정 배송기간을 초과한 경우에는 그로 인한 이용자의 손해를 배상하여야 합니다. 다만 “몰”이 고의․과실이 없음을 입증한 경우에는 그러하지 아니합니다.
 
제14조(환급) “몰”은 이용자가 구매신청한 재화 등이 품절 등의 사유로 인도 또는 제공을 할 수 없을 때에는 지체 없이 그 사유를 이용자에게 통지하고 사전에 재화 등의 대금을 받은 경우에는 대금을 받은 날부터 3영업일 이내에 환급하거나 환급에 필요한 조치를 취합니다.
 
제15조(청약철회 등)
 
  ① “몰”과 재화등의 구매에 관한 계약을 체결한 이용자는 「전자상거래 등에서의 소비자보호에 관한 법률」 제13조 제2항에 따른 계약내용에 관한 서면을 받은 날(그 서면을 받은 때보다 재화 등의 공급이 늦게 이루어진 경우에는 재화 등을 공급받거나 재화 등의 공급이 시작된 날을 말합니다)부터 7일 이내에는 청약의 철회를 할 수 있습니다. 다만, 청약철회에 관하여 「전자상거래 등에서의 소비자보호에 관한 법률」에 달리 정함이 있는 경우에는 동 법 규정에 따릅니다.
 
  ② 이용자는 재화 등을 배송 받은 경우 다음 각 호의 1에 해당하는 경우에는 반품 및 교환을 할 수 없습니다.
 
    1. 이용자에게 책임 있는 사유로 재화 등이 멸실 또는 훼손된 경우(다만, 재화 등의 내용을 확인하기 위하여 포장 등을 훼손한 경우에는 청약철회를 할 수 있습니다)
    2. 이용자의 사용 또는 일부 소비에 의하여 재화 등의 가치가 현저히 감소한 경우
    3. 시간의 경과에 의하여 재판매가 곤란할 정도로 재화등의 가치가 현저히 감소한 경우
    4. 같은 성능을 지닌 재화 등으로 복제가 가능한 경우 그 원본인 재화 등의 포장을 훼손한 경우
 
  ③ 제2항제2호 내지 제4호의 경우에 “몰”이 사전에 청약철회 등이 제한되는 사실을 소비자가 쉽게 알 수 있는 곳에 명기하거나 시용상품을 제공하는 등의 조치를 하지 않았다면 이용자의 청약철회 등이 제한되지 않습니다.
 
  ④ 이용자는 제1항 및 제2항의 규정에 불구하고 재화 등의 내용이 표시·광고 내용과 다르거나 계약내용과 다르게 이행된 때에는 당해 재화 등을 공급받은 날부터 3월 이내, 그 사실을 안 날 또는 알 수 있었던 날부터 30일 이내에 청약철회 등을 할 수 있습니다.
 
제16조(청약철회 등의 효과)
 
  ① “몰”은 이용자로부터 재화 등을 반환받은 경우 3영업일 이내에 이미 지급받은 재화 등의 대금을 환급합니다. 이 경우 “몰”이 이용자에게 재화등의 환급을 지연한때에는 그 지연기간에 대하여 「전자상거래 등에서의 소비자보호에 관한 법률 시행령」제21조의2에서 정하는 지연이자율을 곱하여 산정한 지연이자를 지급합니다.
 
  ② “몰”은 위 대금을 환급함에 있어서 이용자가 신용카드 또는 전자화폐 등의 결제수단으로 재화 등의 대금을 지급한 때에는 지체 없이 당해 결제수단을 제공한 사업자로 하여금 재화 등의 대금의 청구를 정지 또는 취소하도록 요청합니다.
 
  ③ 청약철회 등의 경우 공급받은 재화 등의 반환에 필요한 비용은 이용자가 부담합니다. “몰”은 이용자에게 청약철회 등을 이유로 위약금 또는 손해배상을 청구하지 않습니다. 다만 재화 등의 내용이 표시·광고 내용과 다르거나 계약내용과 다르게 이행되어 청약철회 등을 하는 경우 재화 등의 반환에 필요한 비용은 “몰”이 부담합니다.
 
  ④ 이용자가 재화 등을 제공받을 때 발송비를 부담한 경우에 “몰”은 청약철회 시 그 비용을  누가 부담하는지를 이용자가 알기 쉽도록 명확하게 표시합니다.
 
제17조(개인정보보호)
 
  ① “몰”은 이용자의 개인정보 수집시 서비스제공을 위하여 필요한 범위에서 최소한의 개인정보를 수집합니다.
 
  ② “몰”은 회원가입시 구매계약이행에 필요한 정보를 미리 수집하지 않습니다. 다만, 관련 법령상 의무이행을 위하여 구매계약 이전에 본인확인이 필요한 경우로서 최소한의 특정 개인정보를 수집하는 경우에는 그러하지 아니합니다.
 
  ③ “몰”은 이용자의 개인정보를 수집·이용하는 때에는 당해 이용자에게 그 목적을 고지하고 동의를 받습니다.
 
  ④ “몰”은 수집된 개인정보를 목적외의 용도로 이용할 수 없으며, 새로운 이용목적이 발생한 경우 또는 제3자에게 제공하는 경우에는 이용·제공단계에서 당해 이용자에게 그 목적을 고지하고 동의를 받습니다. 다만, 관련 법령에 달리 정함이 있는 경우에는 예외로 합니다.
 
  ⑤ “몰”이 제2항과 제3항에 의해 이용자의 동의를 받아야 하는 경우에는 개인정보관리 책임자의 신원(소속, 성명 및 전화번호, 기타 연락처), 정보의 수집목적 및 이용목적, 제3자에 대한 정보제공 관련사항(제공받은자, 제공목적 및 제공할 정보의 내용) 등 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」 제22조제2항이 규정한 사항을 미리 명시하거나 고지해야 하며 이용자는 언제든지 이 동의를 철회할 수 있습니다.
 
  ⑥ 이용자는 언제든지 “몰”이 가지고 있는 자신의 개인정보에 대해 열람 및 오류정정을 요구할 수 있으며 “몰”은 이에 대해 지체 없이 필요한 조치를 취할 의무를 집니다. 이용자가 오류의 정정을 요구한 경우에는 “몰”은 그 오류를 정정할 때까지 당해 개인정보를 이용하지 않습니다.
 
  ⑦ “몰”은 개인정보 보호를 위하여 이용자의 개인정보를 취급하는 자를  최소한으로 제한하여야 하며 신용카드, 은행계좌 등을 포함한 이용자의 개인정보의 분실, 도난, 유출, 동의 없는 제3자 제공, 변조 등으로 인한 이용자의 손해에 대하여 모든 책임을 집니다.
 
  ⑧ “몰” 또는 그로부터 개인정보를 제공받은 제3자는 개인정보의 수집목적 또는 제공받은 목적을 달성한 때에는 당해 개인정보를 지체 없이 파기합니다.
 
  ⑨ “몰”은 개인정보의 수집·이용·제공에 관한 동의 란을 미리 선택한 것으로 설정해두지 않습니다. 또한 개인정보의 수집·이용·제공에 관한 이용자의 동의거절시 제한되는 서비스를 구체적으로 명시하고, 필수수집항목이 아닌 개인정보의 수집·이용·제공에 관한 이용자의 동의 거절을 이유로 회원가입 등 서비스 제공을 제한하거나 거절하지 않습니다.
 
제18조(“몰“의 의무)
 
  ① “몰”은 법령과 이 약관이 금지하거나 공서양속에 반하는 행위를 하지 않으며 이 약관이 정하는 바에 따라 지속적이고, 안정적으로 재화․용역을 제공하는데 최선을 다하여야 합니다.
 
  ② “몰”은 이용자가 안전하게 인터넷 서비스를 이용할 수 있도록 이용자의 개인정보(신용정보 포함)보호를 위한 보안 시스템을 갖추어야 합니다.
 
  ③ “몰”이 상품이나 용역에 대하여 「표시․광고의 공정화에 관한 법률」 제3조 소정의 부당한 표시․광고행위를 함으로써 이용자가 손해를 입은 때에는 이를 배상할 책임을 집니다.
 
  ④ “몰”은 이용자가 원하지 않는 영리목적의 광고성 전자우편을 발송하지 않습니다.
 
제19조(회원의 ID 및 비밀번호에 대한 의무)
 
  ① 제17조의 경우를 제외한 ID와 비밀번호에 관한 관리책임은 회원에게 있습니다.
 
  ② 회원은 자신의 ID 및 비밀번호를 제3자에게 이용하게 해서는 안됩니다.
 
  ③ 회원이 자신의 ID 및 비밀번호를 도난당하거나 제3자가 사용하고 있음을 인지한 경우에는 바로 “몰”에 통보하고 “몰”의 안내가 있는 경우에는 그에 따라야 합니다.
 
제20조(이용자의 의무) 이용자는 다음 행위를 하여서는 안 됩니다.
 
    1. 신청 또는 변경시 허위 내용의 등록
    2. 타인의 정보 도용
    3. “몰”에 게시된 정보의 변경
    4. “몰”이 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는 게시
    5. “몰” 기타 제3자의 저작권 등 지적재산권에 대한 침해
    6. “몰” 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위
    7. 외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 정보를 몰에 공개 또는 게시하는 행위
 
제21조(연결“몰”과 피연결“몰” 간의 관계)
 
  ① 상위 “몰”과 하위 “몰”이 하이퍼링크(예: 하이퍼링크의 대상에는 문자, 그림 및 동화상 등이 포함됨)방식 등으로 연결된 경우, 전자를 연결 “몰”(웹 사이트)이라고 하고 후자를 피연결 “몰”(웹사이트)이라고 합니다.
 
  ② 연결“몰”은 피연결“몰”이 독자적으로 제공하는 재화 등에 의하여 이용자와 행하는 거래에 대해서 보증 책임을 지지 않는다는 뜻을 연결“몰”의 초기화면 또는 연결되는 시점의 팝업화면으로 명시한 경우에는 그 거래에 대한 보증 책임을 지지 않습니다.
 
제22조(저작권의 귀속 및 이용제한)
 
  ① “몰“이 작성한 저작물에 대한 저작권 기타 지적재산권은 ”몰“에 귀속합니다.
 
  ② 이용자는 “몰”을 이용함으로써 얻은 정보 중 “몰”에게 지적재산권이 귀속된 정보를 “몰”의 사전 승낙 없이 복제, 송신, 출판, 배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안됩니다.
 
  ③ “몰”은 약정에 따라 이용자에게 귀속된 저작권을 사용하는 경우 당해 이용자에게 통보하여야 합니다.
 
제23조(분쟁해결)
 
  ① “몰”은 이용자가 제기하는 정당한 의견이나 불만을 반영하고 그 피해를 보상처리하기 위하여 피해보상처리기구를 설치․운영합니다.
 
  ② “몰”은 이용자로부터 제출되는 불만사항 및 의견은 우선적으로 그 사항을 처리합니다. 다만, 신속한 처리가 곤란한 경우에는 이용자에게 그 사유와 처리일정을 즉시 통보해 드립니다.
 
  ③ “몰”과 이용자 간에 발생한 전자상거래 분쟁과 관련하여 이용자의 피해구제신청이 있는 경우에는 공정거래위원회 또는 시·도지사가 의뢰하는 분쟁조정기관의 조정에 따를 수 있습니다.
 
제24조(재판권 및 준거법)
 
  ① “몰”과 이용자 간에 발생한 전자상거래 분쟁에 관한 소송은 제소 당시의 이용자의 주소에 의하고, 주소가 없는 경우에는 거소를 관할하는 지방법원의 전속관할로 합니다. 다만, 제소 당시 이용자의 주소 또는 거소가 분명하지 않거나 외국 거주자의 경우에는 민사소송법상의 관할법원에 제기합니다.
 
  ② “몰”과 이용자 간에 제기된 전자상거래 소송에는 한국법을 적용합니다.

  부칙
  1. 이 약관은 2022년 12월 23일 부터 적용됩니다.  
`

const contentText = `
제1장 총칙

제1조(목적) 이 약관은 사이버몰 이용계약, 디지털콘텐츠 및 온라인 서비스 이용계약에서 사업자와 회원의 권리 및 의무 등에 관한 사항을 규정함을 목적으로 합니다. 
제2조(정의) 이 약관에서 사용하는 용어의 뜻은 다음과 같습니다.   
1. "사이버몰"이라 함은 디지털콘텐츠 또는 온라인 서비스(이하 "디지털콘텐츠등"이라 합니다)를 거래할 수 있는 가상의 영업장을 말합니다. 

2. "회원"라 함은 사업자가 제공하는 디지털콘텐츠등을 이용하는 자를 말합니다. 

3. "회원"이라 함은 사업자와 사이버몰 이용계약을 체결하고, 회원 아이디(ID)를 부여받은 회원를 말합니다. 

4. "비회원"이라 함은 회원이 아닌 회원를 말합니다. 

5. "소비자"라 함은 「전자상거래 등에서의 소비자보호에 관한 법률」제2조제5호에서 정한 자를 말합니다. 

6. "이용사업자"라 함은 소비자가 아닌 회원를 말합니다. 

7. "디지털콘텐츠"라 함은 부호ㆍ문자ㆍ도형ㆍ색채ㆍ음성ㆍ음향ㆍ이미지 및 영상 등(이들의 복합체를 포함합니다)의 자료 또는 정보로서 그 보존 및 이용의 효용을 높일 수 있도록 디지털 형태로 제작하거나 처리한 것을 말합니다. 

8. "온라인 서비스"라 함은 사업자가 사이버몰에서 회원에게 제공하는 서비스를 말합니다. 

9. "아이디(ID)"라 함은 사이버몰에서 회원을 식별하기 위한 문자 또는 숫자 등의 조합을 말합니다. 

10. "비밀번호(PASSWORD)"라 함은 회원임을 확인하기 위한 것으로써 아이디와 합치되는 문자 또는 숫자 등의 조합 또는 생체인식정보 등을 말합니다. 

11. "포인트"라 함은 회원가 디지털콘텐츠등의 대금으로 지급할 수 있는 전자결제수단으로써 사업자가 발행한 것을 말합니다. 

12. "계속거래"라 함은 1개월 이상에 걸쳐 계속적으로 또는 부정기적으로 디지털콘텐츠등을 제공하는 계약으로 중도에 해지할 경우 대금 환급의 제한 또는 위약금에 관한 약정이 있는 거래를 말합니다. 

제3조(약관의 변경 등)   ① 사업자는 「콘텐츠산업진흥법」, 「전자상거래 등에서의 소비자보호에 관한 법률」, 「약관의 규제에 관한 법률」 등 관련법령을 위반하지 않는 범위에서 이 약관을 변경할 수 있습니다. 
② 제1항에 따라 약관을 변경할 경우, 사업자는 시행일 및 변경사유를 명시하여 개정 약관(현행 약관과의 비교표를 포함합니다)을 사이버몰 초기화면에서 다음 각 호에서 정하는 기간 및 시행일로부터 상당한 기간 동안 공지합니다. 다만, 정당한 사유가 있는 경우, 개정약관 등을 확인할 수 있는 링크 등을 제공할 수 있습니다. 

1. 변경하고자 하는 약관의 내용이 중대하거나 회원에게 불리한 경우 : 시행일로부터 30일 전부터 시행일까지 

2. 제1호에 해당하지 않는 경우 : 시행일로부터 7일 전부터 시행일까지 

③ 제1항에 따라 약관을 변경할 경우에 사업자는 회원에게 다음 각 호에서 정하는 기한까지 변경 약관(현행 약관과의 비교표를 포함합니다) 및 변경사유를 전자우편주소로 전송하고, 동의를 받습니다. 

1. 변경하고자 하는 약관의 내용이 중대하거나 회원에게 불리한 경우 : 시행일로부터 30일 전까지 

2. 제1호에 해당하지 않는 경우 : 시행일로부터 7일 전까지 

④ 변경 약관은 제3항에 따라 동의한 회원에게 적용됩니다. 다만, 사업자가 제3항에 따라 회원에게 고지하면서 『이 기한 내에 변경 약관의 적용에 대해 거절의 의사를 표시하지 않으면 동의한 것으로 간주합니다』라는 사실을 명확히 표시한 경우에 그 기한까지 거절의 의사를 표시하지 않은 회원에게도 적용됩니다. 

⑤ 사업자는 변경 약관의 적용을 거절한 회원에게 변경전 약관을 적용합니다. 다만, 변경전 약관을 적용할 수 없는 정당한 사유 또는 불가피한 사유가 있는 경우에는 그러하지 않습니다. 

⑥ 제5항단서의 경우에 회원은 사이버몰 이용계약 또는 디지털콘텐츠등의 이용계약을 해지할 수 있습니다. 이 경우에 사업자는 회원에게 위약금 등을 청구하지 않습니다. 

⑦ 제6항의 경우, 사업자는 회원이 입은 손해 또는 손실을 배상 또는 보상합니다. 다만, 다음 각 호의 어느 하나에 해당하는 경우에는 그러하지 않습니다. 

1. 사업자에게 책임이 없는 경우. 다만, 손실보상은 제외합니다. 

2. 관련 법령의 제ㆍ개정에 따른 약관변경의 경우 

제4조(약관의 해석 등)   ① 사업자는 신의성실의 원칙에 따라 공정하게 이 약관을 해석하며, 회원에 따라 다르게 해석하지 않습니다. 
② 사업자는 이 약관의 뜻이 명백하지 아니한 경우에 회원에게 유리하게 해석합니다. 

③ 이 약관에서 정하지 아니한 사항에 대해서는 「콘텐츠산업진흥법」, 「전자상거래 등에서의 소비자보호에 관한 법률」, 「약관의 규제에 관한 법률」「개인정보 보호법」 등 관련법 또는 사업자와 회원간 합의에서 정한 바에 따릅니다. 

제5조(개별 약정의 우선 등)   ① 사업자와 회원가 약관의 내용과 다르게 합의한 사항이 있을 때에는 그 합의 사항은 이 약관보다 우선하여 적용됩니다. 
② 제1항에 따른 개별 약정이 「콘텐츠산업진흥법」, 「전자상거래 등에서의 소비자보호에 관한 법률」, 「약관의 규제에 관한 법률」 등 관련법령의 강행규정을 위반하여 무효인 경우에 이 약관에서 정한 바에 따릅니다. 

제6조(신의성실의 원칙) 사업자와 회원는 상대방의 신의에 좇아 성실하게 사이버몰 및 디지털콘텐츠등 이용계약에 따른 권리를 행사하고, 의무를 이행합니다. 
      제2장 사이버몰 이용계약 및 개인정보보호

      제1절 사이버몰 이용계약

제7조(사업자의 관리의무) 사업자는 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」, 「개인정보 보호법」 등 관련 법률에 따라 사이버몰의 안정성 유지 및 회원의 개인정보보호 등을 위한 보안시스템을 구축하고, 운영합니다. 
제8조(허위정보등의 제공 등 및 이용계약의 성립)   ① 회원가 사실과 다르게 개인정보를 제공하거나 타인의 정보를 제공하여 사이버몰 이용을 위해 청약한 경우 또는 청소년이 이용할 수 없는 사이버몰 이용을 위해 청약한 경우, 사업자는 승낙하지 않거나 승낙을 유보할 수 있습니다. 
② 사업자의 승낙이 수신확인통지형태로 회원에게 도달한 시점에 사이버몰 이용계약이 성립한 것으로 봅니다. 

제9조(미성년자 등의 회원가입과 법정대리인 등의 취소 등)   ① 만 19세 미만의 회원가 법정대리인의 동의를 받지 아니하고, 사이버몰의 회원으로 가입을 한 경우에 회원ㆍ법정대리인 또는 승계인은 사이버몰 이용계약을 취소할 수 있습니다. 다만, 「민법」 등에 따라 미성년자가 취소할 수 없는 경우에는 그러하지 않습니다. 
② 제1항본문의 경우에 사업자는 회원의 법정대리인이 추인하기 전까지 사이버몰 이용계약을 철회할 수 있습니다. 다만, 사업자가 회원이 미성년자라는 사실을 알았을 경우에는 철회할 수 없습니다. 

③ 제1항 또는 제2항에 따라 사이버몰 이용계약이 취소 또는 철회된 경우에 사업자는 회원으로부터 받은 대금을 환급하며, 위약금 등을 청구하지 않습니다. 다만, 사업자가 악의인 경우에 대금을 받은 날로부터 환급할 때까지의 기간에 대한 이자를 더하여 환급하며, 회원에게 손해가 있는 경우에 그 손해에 대해 배상하여야 합니다. 

④ 제1항 또는 제2항에 따라 사이버몰 이용계약이 취소 또는 철회된 경우에 사업자는 그 회원의 정보를 지체없이 삭제합니다. 다만, 「전자상거래 등에서의 소비자보호에 관한 법률」 등에 따라 법정기간 동안 정보를 보존하여야 하는 경우에는 그러하지 않습니다. 

제10조(아이디 및 비밀번호 관리의무 등)   ① 회원은 아이디와 비밀번호를 선량한 관리자의 주의의무로 관리하며, 제3자에게 이용허락할 수 없습니다. 다만, 사업자가 동의한 경우에는 그러하지 않습니다. 
② 제1항본문을 위반한 경우에 사업자는 회원에게 발생한 손해에 대해 책임을 지지 않습니다. 다만, 회원의 손해발생에 대해 사업자에게 책임이 있는 경우에 사업자는 그 범위내에서 책임을 부담합니다. 

③ 회원은 아이디 및 비밀번호가 도용되거나 제3자에 의해 사용되고 있음을 알게 된 경우에 즉시 사업자에게 그 사실을 통지하고, 사업자의 안내에 따라야 합니다. 

④ 제3항의 경우, 회원이 사업자에게 그 사실을 통지하지 않거나 통지한 경우에도 사업자의 안내에 따르지 않아 발생한 회원의 손해에 대해 사업자는 책임을 지지 않습니다. 다만, 사업자에게 책임있는 경우에는 그 범위내에서 책임을 부담합니다. 

⑤ 회원은 도용 등을 방지하기 위해 주기적으로 비밀번호를 변경하며, 사업자는 회원에게 비밀번호의 변경을 권고할 수 있습니다. 

제11조(회원에 대한 통지)   ① 개별 회원에 대한 통지를 하는 경우, 사업자는 회원이 지정한 전자우편주소로 그 내용을 전송합니다. 
② 전체 회원에게 통지할 경우, 사업자는 회원이 쉽게 알 수 있도록 사이버몰의 게시판 등에 7일 이상 그 내용을 게시합니다. 다만, 회원의 사이버몰 이용계약과 관련하여 영향을 미치는 사항에 대하여는 제1항에 따른 통지를 합니다. 

③ 제1항 및 제2항단서에도 불구하고, 회원이 지정한 전자우편주소가 없는 경우 또는 회원이 지정한 전자우편주소로 통지할 수 없는 경우에 사업자는 제2항본문에서 정한 방법으로 통지할 수 있습니다. 이 경우, 사업자가 회원의 전화번호를 알고 있는 때에는 그 내용을 확인할 수 있는 방법을 전화 또는 문자로 안내합니다. 

제12조(사이버몰의 이용시간 및 일시 이용 중지 등)   ① 사업자는 회원가 연중 및 24시간 사이버몰을 이용할 수 있도록 사이버몰을 운영합니다. 
② 사업자는 컴퓨터 등 정보통신설비의 보수점검, 교체 및 고장, 통신두절 또는 운영상 상당한 이유가 있는 경우 사이버몰 이용을 일시적으로 중지할 수 있습니다. 

③ 제2항의 경우, 사업자는 제11조에 따라 회원에게 통지합니다. 다만, 사업자가 사전에 통지할 수 없는 부득이한 사유가 있는 경우 사후에 통지할 수 있습니다. 

④ 사업자는 사이버몰 이용이 일시적으로 중단됨으로 인하여 회원에게 발생한 손해에 대해 배상합니다. 다만, 사업자가 고의 또는 과실이 없음을 증명한 경우에는 그러하지 않습니다. 

⑤ 사업자는 원활한 사이버몰 운영을 위해 필요한 경우에 정기점검을 실시할 수 있으며, 정기점검일로부터 최소 1주일 이전에 정기점검시간을 사이버몰 초기화면에 게시합니다. 

⑥ 사업종목의 전환ㆍ폐업ㆍ파산 등의 이유로 사업자가 사이버몰을 운영할 수 없는 경우에 사업자는 제11조에 따라 회원에게 통지하고, 보상기준에 따라 회원에게 보상합니다. 다만, 사업자가 보상기준을 고지하지 아니하거나 고지한 보상기준에 대해 회원가 거절한 경우에 회원와 합의하여 보상합니다. 

제13조(포인트)   ① 사업자는 포인트를 회원에게 판매하거나 무상으로 회원에게 포인트를 제공할 수 있습니다. 
② 회원는 유효기간 내에 포인트를 사용하며, 유효기간이 경과한 포인트는 소멸합니다. 

③ 포인트의 유효기간은 다음 각 호의 구분에 따릅니다. 

1. 유상포인트 : 구입일로 5년 

2. 무상포인트 : 사업자가 표시한 기간 

④ 회원가 디지털콘텐츠등의 대금을 포인트로 지급할 경우에 사업자는 무상포인트를 먼저 차감하며, 잔여 금액에 대해 유상포인트를 차감합니다. 

⑤ 사업자가 포인트정책을 변경하거나 폐지한 경우에 다음 각 호에서 정한 바에 따라 잔여 포인트에 대해 환금 또는 보상합니다. 다만, 무상포인트의 경우에 사업자가 미사용한 포인트에 대해 보상하지 않음을 명확히 공지한 경우에는 그러하지 않습니다. 

1. 유상포인트 : 구입가격에 잔여 포인트의 비율을 곱하여 산정한 금액 

2. 무상포인트 : 사업자가 공지한 보상기준. 다만, 사업자가 보상기준을 공지하지 않은 경우에 유상포인트의 판매가격을 무상포인트에 적용하여 제1호에 따라 보상합니다. 

제14조(정보의 제공 및 광고정보의 전송 등)   ① 사업자는 회원가 사이버몰 또는 디지털콘텐츠등의 이용에 필요한 정보를 사이버몰의 게시판 등에 게시하거나 비상업용 정보에 한해 회원의 전자우편주소로 전송 등의 방법으로 회원에게 제공할 수 있습니다. 이 경우에 회원는 언제든지 수신 거절할 수 있으며, 사업자는 수신을 거절한 회원에게 전화ㆍ전자우편 등으로 정보를 제공하지 않습니다. 
② 사업자는 수신동의한 회원에 한해 전자우편 또는 휴대전화 문자 서비스 등을 이용하여 영리목적의 광고성 정보를 전송할 수 있습니다. 다만, 디지털콘텐츠등의 거래관계를 통하여 회원로부터 직접 연락처를 수집한 사업자가 그 거래가 종료한 날로부터 6개월 이내에 자신이 처리하고 회원와 거래한 것과 같은 종류의 디지털콘텐츠등에 대한 영리목적의 광고성 정보를 전송하는 경우에는 수신동의하지 않은 회원에게 그 정보를 전송할 수 있습니다. 

③ 제2항에도 불구하고, 오후 9시부터 그 다음 날 8시까지의 시간에 영리목적의 광고성 정보를 전송하려는 사업자는 회원로부터 별도의 사전동의를 받습니다. 다만, 전자우편으로 정보를 전송하는 경우에는 그러하지 않습니다. 

④ 제2항의 경우에 회원는 그 동의를 언제든지 철회할 수 있습니다. 

⑤ 사업자는 제1항 또는 제4항에 따라 수신거절 또는 동의철회를 하고자 하는 회원가 전화요금 등의 비용을 부담하지 않고 수신을 거절하거나 동의를 철회할 수 있도록 필요한 조치를 취합니다. 

⑥ 사업자는 제1항 또는 제4항에 따라 수신을 거절하거나 동의를 철회한 회원에게 그 처리결과를 통지합니다. 

제15조(저작권등의 귀속 등)   ① 사업자가 창작한 저작물에 대한 저작권 기타 지식재산권(이하 "저작권등"이라 합니다)은 사업자가 갖습니다. 
② 회원는 사업자가 제공하는 디지털콘텐츠등을 이용함으로써 얻은 정보에 대해 사업자의 사전승낙 없이 복제, 전송, 출판, 배포 등을 하거나 제3자에게 이를 하도록 하지 않습니다. 다만, 「저작권법」등에 따라 허용되는 경우에는 그러하지 않습니다. 

③ 사업자가 회원의 저작물을 사용하는 경우에 회원의 사전허락을 받습니다. 다만, 「저작권법」등에 따라 허용되는 경우에는 그러하지 않습니다. 

제16조(불법정보등의 게시금지 및 삭제)   ① 회원는 사이버몰의 게시판 등을 이용하여 다음 각 호의 어느 하나에 해당하는 불법정보를 게시하거나 다른 회원에게 제공하지 않습니다. 
1. 음란한 부호ㆍ문언ㆍ음향ㆍ화상 또는 영상을 배포ㆍ판매ㆍ임대하거나 공공연하게 전시하는 내용의 정보 

2. 사람을 비방할 목적으로 공공연하게 사실이나 거짓의 사실을 드러내어 타인의 명예를 훼손하는 내용의 정보 

3. 공포심이나 불안감을 유발하는 부호ㆍ문언ㆍ음향ㆍ화상 또는 영상을 반복적으로 상대방에게 도달하도록 하는 내용의 정보 

4. 정당한 사유 없이 정보통신시스템, 데이터 또는 프로그램 등을 훼손ㆍ멸실ㆍ변경ㆍ위조하거나 그 운용을 방해하는 내용의 정보 

5. 「청소년 보호법」에 따른 청소년유해매체물로서 상대방의 연령 확인, 표시의무 등 법령에 따른 의무를 이행하지 아니하고 영리를 목적으로 제공하는 내용의 정보 

6. 법령에 따라 금지되는 사행행위에 해당하는 내용의 정보 

7. 개인정보 보호에 관한 법령을 위반하여 개인정보를 거래하는 내용의 정보 

8. 총포ㆍ화약류(생명ㆍ신체에 위해를 끼칠 수 있는 폭발력을 가진 물건을 포함합니다)를 제조할 수 있는 방법이나 설계도 등의 정보 

9. 법령에 따라 분류된 비밀 등 국가기밀을 누설하는 내용의 정보 

10. 「국가보안법」에서 금지하는 행위를 수행하는 내용의 정보 

11. 법률에서 금지하는 재화 또는 서비스에 대한 광고성 정보 

12. 그 밖에 범죄를 목적으로 하거나 교사(敎唆) 또는 방조하는 내용의 정보 

② 회원는 사이버몰의 게시판 등을 이용하여 「청소년보호법」제2조제3호에 따른 청소년유해매체물 또는 이에 접속하거나 이용할 수 있는 정보를 게시하지 않습니다. 다만, 「청소년보호법」에 따라 청소년이 아닌 회원만이 이용할 수 있는 게시판 등에의 게시등은 그러하지 않습니다. 

③ 회원는 영리목적의 광고성 정보를 사이버몰의 게시판 등에 게시하려면 사업자의 사전 동의를 받아야 합니다. 다만, 별도의 권한 없이 누구든지 쉽게 접근하여 글을 게시할 수 있는 게시판의 경우에는 사전 동의를 받지 않고 게시할 수 있습니다. 

④ 제3항에도 불구하고, 사업자가 명시적으로 게시 거부의사를 표시하거나 사전 동의를 철회한 경우에 회원는 영리목적의 광고성 정보를 게시하지 않습니다. 

⑤ 제1항부터 제4항까지를 위반한 정보에 대해 사업자는 그 정보를 게시한 자에게 통지하지 않고, 그 정보를 삭제할 수 있습니다. 이 경우에 사업자는 그 정보의 삭제에 대해 책임을 지지 않습니다. 

제17조(임의의 차단조치)   ① 사업자는 사이버몰의 게시판 등에 게시된 정보가 사생활 침해 또는 명예훼손 등 타인의 권리를 침해한다고 인정되면 임의로 해당 정보에 대한 접근을 차단하는 조치(이하 "차단조치"라 합니다)를 취할 수 있습니다. 
② 제1항에 따른 차단조치를 취한 사업자는 차단조치의 사실을 그 정보가 게시된 게시판 등에 공시하는 등의 방법으로 회원가 알 수 있게 합니다.
③ 차단조치를 받은 회원는 구매한 디지털 컨텐츠의 제공을 요청 할 수 있고, 사업자는 회원의 요청에 따라 디지털 컨텐츠를 메일 발송등의 방법으로 제공하여야 합니다. 

제18조(정보의 삭제요청 등)   ① 사이버몰을 통하여 일반에게 공개를 목적으로 제공된 정보로 사생활 침해나 명예훼손 등 타인의 권리가 침해된 경우, 그 침해를 받은 자는 해당 정보를 처리한 사업자에게 침해사실을 소명하여 그 정보의 삭제 또는 반박내용의 게재(이하 "삭제등"이라 합니다)를 요청할 수 있습니다. 
② 사업자는 제1항에 따른 해당 정보의 삭제등을 요청받으면 지체 없이 삭제ㆍ임시조치 등의 필요한 조치를 하고, 즉시 신청인 및 정보게재자에게 알립니다. 이 경우, 사업자는 필요한 조치를 한 사실을 해당 게시판에 공시하는 등의 방법으로 회원가 알 수 있도록 합니다. 

③ 사업자는 사이버몰에 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」제42조에 따른 표시방법을 지키지 아니하는 청소년유해매체물이 게시되어 있거나 같은 법 제42조의2에 따른 청소년 접근을 제한하는 조치 없이 청소년유해매체물을 광고하는 내용이 전시되어 있는 경우에는 지체 없이 그 내용을 삭제합니다. 

④ 제1항에 따른 정보의 삭제요청에도 불구하고, 사업자는 권리의 침해 여부를 판단하기 어렵거나 이해당사자 간에 다툼이 예상되는 경우에 제17조에 따른 임시조치를 취할 수 있습니다. 

제19조(회원탈퇴 및 자격 상실 등)   ① 회원은 사업자에게 언제든지 탈퇴를 요청할 수 있으며, 사업자는 즉시 회원탈퇴를 처리합니다. 다만, 정당한 사유가 있는 경우에 탈퇴처리를 유보할 수 있습니다. 
② 회원이 사이버몰을 이용하면서 다음 각 호의 어느 하나에 해당하는 행위를 한 경우, 사업자는 회원자격 또는 디지털콘텐츠등의 이용을 일시적으로 제한하거나 정지시킬 수 있습니다. 

1. 다른 회원의 사이버몰 이용을 방해하거나 정보를 도용하는 등 사이버몰 운영 질서를 침해하는 행위 

2. 계약 및 약관에서 금지하는 행위 

3. 법령ㆍ선량한 풍속 기타 사회질서에 반하는 행위 

③ 회원자격이 제한 또는 정지된 적이 있는 회원이 제2항에서 정한 행위를 2회 이상 하거나 30일 이내에 회원자격 제한 또는 정지사유가 시정되지 아니한 경우에 사업자는 그 회원의 자격을 상실시킬 수 있습니다. 

④ 사업자가 회원자격을 상실시킨 경우에 회원등록을 말소합니다. 다만, 정당한 사유가 있는 경우에 회원등록의 말소를 유보할 수 있습니다. 

⑤ 사업자는 제2항 또는 제3항에 따라 회원자격의 제한 또는 정지, 자격상실 등의 조치를 취한 경우에 제11조에 따라 그 회원에게 그 사실 및 사유를 지체없이 통지하고, 다음 각 호에 따른 기간 동안 소명기회를 부여합니다. 

1. 회원자격의 제한 또는 정지 등 : 1주일 이상 

2. 자격상실 : 30일 이상 

⑥ 사업자는 제2항 또는 제3항에 따른 회원자격의 제한 또는 정지, 자격상실 등과 별도로 그 회원의 행위로 인해 손해를 입은 경우에 배상을 청구할 수 있습니다. 다만, 회원이 고의 또는 과실없음을 증명한 경우에는 그러하지 않습니다. 

      제2절 개인정보보호

제20조(개인정보보호)   ① 사업자는 개인정보의 수집ㆍ이용, 제공 등 개인정보의 처리 및 보호와 관련하여 「개인정보 보호법」을 준수합니다. 
② 개인정보의 열람, 정정, 처리정지 등 회원의 권리는 「개인정보 보호법」에 따라 보호받습니다. 

제21조(접근권한에 대한 동의)   ① 사업자는 디지털콘텐츠등을 회원에게 제공하기 위하여 회원의 이동통신단말장치 내에 저장되어 있는 정보 및 이동통신단말장치에 설치된 기능에 대하여 접근할 수 있는 권한(이하 "접근권한"이라 합니다)이 필요한 경우에 다음 각 호의 사항을 회원가 명확하게 인지할 수 있도록 알리고, 회원의 동의를 받습니다. 
1. 디지털콘텐츠등을 제공하기 위하여 반드시 필요한 접근권한인 경우 

가. 접근권한이 필요한 정보 및 기능의 항목 

나. 접근권한이 필요한 이유 

2. 디지털콘텐츠등을 제공하기 위하여 반드시 필요한 접근권한이 아닌 경우 

가. 접근권한이 필요한 정보 및 기능의 항목 

나. 접근권한이 필요한 이유 

다. 접근권한 허용에 대하여 동의하지 아니할 수 있다는 사실 

② 사업자는 해당 디지털콘텐츠등을 제공하기 위하여 반드시 필요하지 아니한 접근권한을 설정하는 데 회원가 동의하지 아니한다는 이유로 회원에게 해당 디지털콘텐츠등의 제공을 거부하지 않습니다. 

제22조(손해배상책임)   ① 회원는 사업자가 「개인정보 보호법」을 위반한 행위로 손해를 입으면 사업자에게 손해배상을 청구할 수 있습니다. 이 경우, 사업자는 고의 또는 과실이 없음을 증명한 경우에는 그러하지 않습니다. 
② 사업자의 고의 또는 중대한 과실로 인하여 개인정보가 분실ㆍ도난ㆍ유출ㆍ위조ㆍ변조 또는 훼손된 경우로서 회원에게 손해가 발생한 때에는 회원는 그 손해액의 3배를 넘지 아니하는 범위에서 손해배상을 청구할 수 있습니다. 다만, 사업자가 고의 또는 중대한 과실이 없음을 증명한 경우에는 그러하지 않습니다. 

제23조(법정손해배상의 청구) 제22조제1항에도 불구하고, 회원는 사업자의 고의 또는 과실로 인하여 개인정보가 분실ㆍ도난ㆍ유출ㆍ위조ㆍ변조 또는 훼손된 경우에는 300만원 이하의 범위에서 상당한 금액을 손해액으로 하여 배상을 청구할 수 있습니다. 다만, 사업자가 고의 또는 과실 없음을 증명한 경우에는 그러하지 않습니다. 
      제3장 디지털콘텐츠 또는 온라인 서비스 이용계약

      제1절 디지털콘텐츠등의 제공 및 대금의 지급

제24조(계약서의 교부) 사업자는 디지털콘텐츠등의 이용계약이 체결된 경우에 다음 각 호의 사항이 기재된 계약서(전자문서를 포함합니다. 이하 같습니다)를 디지털콘텐츠등을 제공할 때까지 회원에게 발급합니다. 다만, 계약을 체결한 회원가 동의한 경우 또는 사업자가 책임없는 사유로 회원의 주소(전자우편주소를 포함합니다)를 알 수 없어 회원에게 계약서를 발급할 수 없는 경우에는 디지털콘텐츠등을 제공받는 자에게 계약서를 발급할 수 있습니다.   
1. 디지털콘텐츠등의 제작자ㆍ공급자 및 사업자의 상호, 대표자의 성명ㆍ주소 및 전화번호 등. 다만, 제작자의 경우에는 성명(법인인 경우에 상호 및 대표자의 성명을 말합니다)으로 한정합니다. 

2. 디지털콘텐츠등의 명칭ㆍ종류 및 내용 

3. 디지털콘텐츠등의 정보에 관한 사항. 이 경우, 디지털콘텐츠에 표시된 기재로 계약서에의 기재를 대신할 수 있습니다. 

4. 디지털콘텐츠등의 가격(가격이 결정되어 있지 아니한 경우에는 가격을 결정하는 구체적인 방법)과 그 지급방법 및 지급시기 

5. 디지털콘텐츠등의 제공방법 및 제공시기 

6. 청약의 철회 및 계약의 해제(이하 "청약철회등"이라 합니다)의 기한ㆍ행사방법 및 효과에 관한 사항(청약철회등의 권리를 행사하는 데에 필요한 서식을 포함합니다) 

7. 디지털콘텐츠등의 교환ㆍ반품ㆍ보증과 그 대금 환불 및 환불의 지연에 따른 배상금 지급의 조건ㆍ절차 

8. 디지털콘텐츠등의 전송ㆍ설치 등을 할 때 필요한 기술적 사항 

9. 회원피해보상의 처리, 디지털콘텐츠등에 대한 불만 처리 및 회원와 사업자 사이의 분쟁 처리에 관한 사항 

10. 거래에 관한 약관(그 약관의 내용을 확인할 수 있는 방법을 포함합니다) 

11. 디지털콘텐츠등의 가격 외에 교환ㆍ반품 비용 등 회원가 추가로 부담하여야 할 사항이 있는 경우 그 내용 및 금액 

12. 판매일시, 판매지역, 판매수량, 인도지역 등 판매조건과 관련하여 제한이 있는 경우 그 내용 

13. 계속거래에 해당하는 경우, 계약 해지와 그 행사방법ㆍ효과에 관한 사항 및 해지권의 행사에 필요한 서식 

제25조(대금의 지급과 과오금의 환급 등)   ① 회원는 사업자가 대금결제화면에서 표시한 지급방법에 따라 디지털콘텐츠등의 이용계약에 따른 대금을 지급합니다. 이 경우, 사업자는 회원가 선택한 대금지급방법에 대해 어떠한 명목의 수수료를 추가로 징수하지 않습니다. 
② 사업자는 제1항에 따른 전자적 대금지급이 이루어지는 경우, 다음 각 호의 사항에 대하여 명확히 고지하고, 고지한 사항에 대해 회원가 확인하여 동의 여부를 선택할 수 있도록 전자적 대금 결제창을 제공합니다. 

1. 디지털콘텐츠등의 내용 및 종류 

2. 디지털콘텐츠등의 가격 

3. 온라인 서비스의 제공기간 

③ 사업자는 제1항에 따른 전자적 대금지급이 이루어진 경우에는 전자문서의 송신 등의 방법으로 회원에게 그 사실을 알리고, 언제든지 회원가 전자적 대금지급과 관련한 자료를 열람할 수 있게 합니다. 

④ 회원가 제1항에 따라 대금을 지급함에 있어서 과오금을 지급한 경우, 사업자는 대금지급방법과 동일한 방법으로 과오금을 환급합니다. 다만, 동일한 방법으로 과오금의 환급이 불가능할 때는 즉시 이를 알리고, 회원가 선택한 방법으로 환급합니다. 

⑤ 사업자의 책임있는 사유로 과오금이 발생한 경우, 사업자는 과오금 전액과 그 수령일로부터 환급할 때까지의 기간에 대해 연 6%(이 보다 높은 이자율을 약정한 경우에는 그 약정이자율)를 곱하여 산정한 이자를 더하여 환급합니다. 다만, 회원의 책임있는 사유로 과오금이 발생한 경우에 사업자는 과오금을 환급하는데 소요되는 비용을 합리적인 범위 내에서 공제하고, 환급할 수 있습니다. 

⑥ 사업자는 회원의 과오금환급청구를 거절할 경우에 과오금이 없음을 증명합니다. 

제26조(디지털콘텐츠등의 제공 등)   ① 사업자는 디지털콘텐츠의 제공방식 또는 이용방식에 따라 회원가 디지털콘텐츠를 이용할 수 있도록 다음 각 호에 따른 조치를 취합니다. 
1. 전송방식 : 회원가 지정한 전자우편주소 또는 사이버몰 내 회원의 계정으로 제공 

2. 다운로드 방식 : 회원가 디지털콘텐츠를 다운받을 수 있는 조치 

3. 스트리밍 방식 : 회원가 디지털콘텐츠를 이용할 수 있는 조치 

② 사업자는 회원가 온라인 서비스 이용계약에 따라 온라인 서비스를 이용할 수 있도록 해당 서비스를 제공합니다. 

③ 사업자는 소비자가 청약을 한 날부터 7일 이내에 제1항 또는 제2항에 따른 조치를 하여야 하고, 소비자가 디지털콘텐츠등을 제공받기 전에 미리 재화등의 대금을 전부 또는 일부 지급한 경우(이하 "선지급식 거래"라 합니다)에는 소비자가 그 대금을 전부 또는 일부 지급한 날부터 3영업일 이내에 제1항 또는 제2항에 따른 조치를 취합니다. 다만, 소비자와 사업자 간에 디지털콘텐츠등의 제공시기에 관하여 따로 약정한 것이 있는 경우에는 그러하지 않습니다. 

④ 사업자는 청약을 받은 디지털콘텐츠등을 제공하기 곤란하다는 것을 알았을 때에는 지체 없이 그 사유를 소비자에게 알리고, 선지급식 거래의 경우에는 소비자가 그 대금의 전부 또는 일부를 지급한 날부터 3영업일 이내에 환급하거나 환급에 필요한 조치를 취합니다. 

⑤ 제4항에 따라 선지급식 거래에서 디지털콘텐츠등의 대금을 환급하거나 환급에 필요한 조치를 취하여야 하는 경우에는 제35조제1항부터 제4항까지를 준용합니다. 

제27조(디지털콘텐츠의 이용 및 업데이트)   ① 회원는 사업자가 제공한 디지털콘텐츠를 이 계약의 내용을 위반하여 이용하지 않습니다. 
② 회원는 사업자가 제공한 디지털콘텐츠에 대해 임의로 변경, 수정 등을 하지 않습니다. 

③ 회원는 사업자의 동의없이 제3자에게 디지털콘텐츠를 제공하지 않습니다. 

④ 회원는 자신의 이용을 위해 자기의 다른 정보처리기기에 디지털콘텐츠를 복제할 수 있습니다. 

⑤ 사업자는 회원가 전송받거나 다운받은 디지털콘텐츠를 안정적으로 계속 사용할 수 있도록 업데이트에 관한 다음 각 호의 정보를 제공합니다. 다만, 디지털콘텐츠의 성질상 업데이트가 필요없는 경우에는 그러하지 않습니다. 

1. 디지털콘텐츠의 업데이트 일자 및 버전 

2. 업데이트의 내용 

3. 업데이트에 필요한 정보처리기기의 용량 

4. 디지털콘텐츠를 업데이트하지 않았을 경우에 발생하는 회원의 불이익. 다만, 불이익이 없는 경우에는 제외합니다. 

5. 업데이트의 비용(유상인 경우로 한정합니다) 

6. 그 밖에 업데이트에 관한 사항 

⑥ 제5항에 따른 디지털콘텐츠의 업데이트는 무료로 합니다. 다만, 정당한 사유가 있는 경우에 사업자는 유료로 할 수 있습니다. 

⑦ 디지털콘텐츠의 업데이트 여부는 회원가 결정하며, 사업자는 회원의 동의없이 회원의 정보처리기기에 저장된 디지털콘텐츠를 업데이트하지 않습니다. 

제28조(대금의 자동결제와 회원에 대한 고지) 디지털콘텐츠등을 계속적으로 이용하는 계약에서 대금이 여러 번에 거쳐 자동으로 결제되기로 약정한 경우, 사업자는 그 결제가 이루어지기 전에 다음 각 호에서 정한 사항이 포함된 결제정보를 회원에게 전자우편 또는 문자메시지 등으로 알립니다.   
1. 금액 

2. 결제시기 

3. 결제수단 

제29조(디지털콘텐츠등의 이용계약의 자동갱신 등)   ① 사업자는 디지털콘텐츠등의 무료이용기간이 경과하여 유료로 전환할 경우, 유료전환 전에 회원의 동의를 받습니다. 
② 사업자는 디지털콘텐츠등의 유료이용기간이 종료하여 이를 갱신하고자 하는 경우, 사전에 회원의 동의를 받습니다. 

③ 사업자가 제1항 또는 제2항에 따라 회원의 동의를 받지 못한 경우, 디지털콘텐츠등의 이용계약은 무료이용기간의 경과 또는 계약기간의 만료로 소멸됩니다. 

④ 제1항 또는 제2항에 따른 회원의 동의 여부에 관하여 다툼이 있는 경우, 사업자가 이를 증명합니다. 

      제2절 디지털콘텐츠등의 이용계약과 회원의 청약철회등

제30조(조작실수방지절차의 마련과 회원의 취소 등)   ① 사업자는 회원가 디지털콘텐츠등의 이용계약을 체결함에 있어 조작 실수 등을 하지 않도록 필요한 절차를 마련하여 제공합니다. 
② 사업자가 제1항에 따른 절차를 제공하지 않아 회원가 중요부분에 대한 착오로 디지털콘텐츠등의 이용계약을 체결한 경우, 회원는 그 계약을 취소할 수 있습니다. 다만, 회원에게 중대한 과실이 있는 경우에는 그러하지 않습니다. 

③ 회원가 제2항에 따라 디지털콘텐츠등의 이용계약을 취소한 경우에 사업자는 회원가 지급한 대금을 환급하며, 회원는 제공받은 디지털콘텐츠를 삭제합니다. 다만, 제공받은 디지털콘텐츠를 삭제할 수 없는 경우에 회원는 이를 이용하지 않고, 제3자가 이용할 수 없게 합니다. 

④ 제3항에도 불구하고, 사업자가 정보통신망을 이용하여 직접 디지털콘텐츠를 삭제할 수 있는 경우에는 사업자가 디지털콘텐츠를 삭제할 수 있습니다. 

제31조(미성년자의 취소 등)   ① 미성년자인 회원가 법정대리인의 동의를 받지 않고, 디지털콘텐츠등의 이용계약을 체결한 경우에 미성년자ㆍ법정대리인 또는 승계인(이하 "미성년자등"이라 합니다)은 그 계약을 취소할 수 있습니다. 다만, 「민법」 등에 따라 미성년자가 취소할 수 없는 경우에는 그러하지 않습니다. 
② 사업자는 미성년자가 행위능력자가 된 후 그 자에게 1개월 이상의 기간을 정하여 제1항본문의 계약에 대해 취소할 것인지 여부의 확답을 촉구할 수 있습니다. 이 경우, 행위능력자로 된 자가 그 기간 내에 확답을 발송하지 아니하면 그 계약을 취소할 수 없습니다. 

③ 사업자는 미성년자가 행위능력자가 되지 못한 경우에 그의 법정대리인에게 제2항의 촉구를 할 수 있습니다. 이 경우, 법정대리인이 그 기간 내에 확답을 발송하지 않으면 그 계약을 취소할 수 없습니다. 

④ 디지털콘텐츠등의 이용계약을 체결할 때에 회원가 미성년자라는 사실을 알지 못한 사업자는 추인이 있을 때까지 그 계약을 철회할 수 있습니다. 

⑤ 디지털콘텐츠등의 이용계약이 제1항에 따라 취소되거나 제4항에 따라 철회된 경우에 사업자는 미성년자가 지급한 대금을 환급하며, 미성년자는 제공받은 디지털콘텐츠를 삭제합니다. 

⑥ 제5항에도 불구하고, 사업자가 정보통신망을 이용하여 직접 디지털콘텐츠를 삭제할 수 있는 경우에는 사업자가 디지털콘텐츠를 삭제할 수 있습니다. 

제32조(소비자의 청약철회등)   ① 사업자와 디지털콘텐츠등의 판매에 관한 계약을 체결한 소비자는 다음 각 호의 기간(거래당사자가 다음 각 호의 기간보다 긴 기간으로 약정한 경우에는 그 기간을 말합니다) 이내에 그 계약에 대해 청약철회등을 할 수 있습니다. 
1. 제24조에 따른 계약서를 받은 날부터 7일. 다만, 계약서를 받은 때보다 디지털콘텐츠등의 제공이 늦게 이루어진 경우에는 디지털콘텐츠등을 제공받거나 디지털콘텐츠등의 제공이 시작된 날부터 7일 

2. 제24조에 따른 계약서를 받지 아니한 경우, 사업자의 주소 등이 적혀 있지 아니한 계약서를 받은 경우 또는 사업자의 주소 변경 등의 사유로 제1호의 기간에 청약철회등을 할 수 없는 경우에는 사업자의 주소를 안 날 또는 알 수 있었던 날부터 7일 

3. 거짓 또는 과장된 사실을 알리거나 기만적 방법을 사용하여 소비자의 청약철회등을 방해하는 행위 또는 청약철회등을 방해할 목적으로 주소, 전화번호, 인터넷도메인 이름 등을 변경하거나 폐지하는 행위가 있는 경우에는 그 방해 행위가 종료한 날부터 7일 

② 소비자는 다음 각 호의 어느 하나에 해당하는 경우에는 사업자의 의사에 반하여 제1항에 따른 청약철회등을 할 수 없습니다. 다만, 사업자가 제6항에 따른 조치를 하지 아니하는 때에는 제2호부터 제5호까지에 해당하는 경우에도 청약철회등을 할 수 있습니다. 

1. 소비자에게 책임이 있는 사유로 디지털콘텐츠가 멸실되거나 훼손된 경우. 다만, 디지털콘텐츠의 내용을 확인하기 위하여 포장 등을 훼손한 경우는 제외합니다. 

2. 소비자의 사용 또는 일부 소비로 디지털콘텐츠의 가치가 현저히 감소한 경우 

3. 시간이 지나 다시 판매하기 곤란할 정도로 디지털콘텐츠의 가치가 현저히 감소한 경우 

4. 복제가 가능한 디지털콘텐츠의 포장을 훼손한 경우 

5. 디지털콘텐츠등의 제공이 개시된 경우. 다만, 가분적 디지털콘텐츠등의 경우에는 제공이 개시되지 아니한 부분에 대하여는 그러하지 않습니다. 

6. 소비자의 주문에 따라 개별적으로 생산되는 디지털콘텐츠등 또는 이와 유사한 디지털콘텐츠등에 대하여 청약철회등을 인정하는 경우 사업자에게 회복할 수 없는 중대한 피해가 예상되는 경우로서 사전에 해당 거래에 대하여 별도로 그 사실을 고지하고 소비자의 서면(전자문서를 포함합니다)에 의한 동의를 받은 경우 

③ 소비자는 제1항 및 제2항에도 불구하고 디지털콘텐츠등의 내용이 표시ㆍ광고의 내용과 다르거나 계약내용과 다르게 이행된 경우에는 그 디지털콘텐츠등을 제공받은 날부터 3개월 이내, 그 사실을 안 날 또는 알 수 있었던 날부터 30일 이내에 청약철회등을 할 수 있습니다. 

④ 제1항 또는 제3항에 따른 청약철회등을 서면으로 하는 경우에는 그 의사표시가 적힌 서면을 발송한 날에 그 효력이 발생합니다. 

⑤ 제1항부터 제3항까지를 적용할 때 디지털콘텐츠의 훼손에 대하여 소비자의 책임이 있는지 여부, 디지털콘텐츠등의 구매에 관한 계약이 체결된 사실 및 그 시기, 디지털콘텐츠등의 제공사실 및 그 시기 등에 관하여 다툼이 있는 경우에는 사업자가 이를 증명합니다. 

⑥ 사업자는 제2항제2호부터 제5호까지에 따라 청약철회등이 불가능한 디지털콘텐츠등의 경우에는 그 사실을 디지털콘텐츠의 포장이나 그 밖에 소비자가 쉽게 알 수 있는 곳에 명확하게 표시하거나 시험 사용 상품을 제공하는 등의 방법으로 배제조치를 취합니다. 다만, 제2항제5호 중 디지털콘텐츠에 대하여 소비자가 청약철회등을 할 수 없는 경우에는 청약철회등이 불가능하다는 사실의 표시와 함께 다음 각 호의 구분에 따른 방법 중 하나 이상의 조치를 취합니다. 

1. 일부 이용의 허용: 디지털콘텐츠의 일부를 미리보기, 미리듣기 등으로 제공 

2. 한시적 이용의 허용: 일정 사용기간을 설정하여 디지털콘텐츠 제공 

3. 체험용 디지털콘텐츠 제공: 일부 제한된 기능만을 사용할 수 있는 디지털콘텐츠 제공 

4. 제1호부터 제3호까지의 방법으로 시험 사용 상품 등을 제공하기 곤란한 경우: 디지털콘텐츠에 관한 정보 제공 

제33조(스트리밍 방식의 디지털콘텐츠 이용계약과 소비자의 청약철회등)   ① 사업자와 스트리밍 방식의 디지털콘텐츠 이용계약을 체결한 소비자 는 다음 각 호의 기간(거래당사자가 다음 각 호의 기간보다 긴 기간으 로 약정한 경우에는 그 기간을 말합니다) 이내에 그 계약에 대해 청약 철회등을 할 수 있습니다. 
1. 제24조에 따른 계약서를 받은 날부터 7일. 다만, 계약서를 받은 때보다 디지털콘텐츠의 제공이 늦게 이루어진 경우에는 디지털콘텐츠를 제공받거나 디지털콘텐츠의 제공이 시작된 날부터 7일 

2. 제24조에 따른 계약서를 받지 아니한 경우, 사업자의 주소 등이 적혀 있지 아니한 계약서를 받은 경우 또는 사업자의 주소 변경 등의 사유로 제1호의 기간에 청약철회등을 할 수 없는 경우에는 사업자의 주소를 안 날 또는 알 수 있었던 날부터 7일 

3. 거짓 또는 과장된 사실을 알리거나 기만적 방법을 사용하여 소비자의 청약철회등을 방해하는 행위 또는 청약철회등을 방해할 목적으로 주소, 전화번호, 인터넷도메인 이름 등을 변경하거나 폐지하는 행위가 있는 경우에는 그 방해 행위가 종료한 날부터 7일 

② 소비자는 다음 각 호의 어느 하나에 해당하는 경우에는 사업자의 의사에 반하여 제1항에 따른 청약철회등을 할 수 없습니다. 다만, 사업자가 제6항에 따른 조치를 하지 아니하는 경우에는 제1호에 해당하는 경우에도 청약철회등을 할 수 있습니다. 

1. 디지털콘텐츠의 제공이 개시된 경우. 다만, 가분적 디지털콘텐츠의 경우에는 제공이 개시되지 아니한 부분에 대하여는 그러하지 않습니다. 

2. 소비자의 주문에 따라 개별적으로 생산되는 디지털콘텐츠 또는 이와 유사한 디지털콘텐츠에 대하여 청약철회등을 인정하는 경우 사업자에게 회복할 수 없는 중대한 피해가 예상되는 경우로서 사전에 해당 거래에 대하여 별도로 그 사실을 고지하고 소비자의 서면(전자문서를 포함합니다)에 의한 동의를 받은 경우 

③ 소비자는 제1항 및 제2항에도 불구하고 디지털콘텐츠의 내용이 표시ㆍ광고의 내용과 다르거나 계약내용과 다르게 이행된 경우에는 그 디지털콘텐츠를 제공받은 날부터 3개월 이내, 그 사실을 안 날 또는 알 수 있었던 날부터 30일 이내에 청약철회등을 할 수 있습니다. 

④ 제1항 또는 제3항에 따른 청약철회등을 서면으로 하는 경우에는 그 의사표시가 적힌 서면을 발송한 날에 그 효력이 발생합니다. 

⑤ 제1항부터 제3항까지를 적용할 때 디지털콘텐츠에 관한 계약이 체결된 사실 및 그 시기, 디지털콘텐츠의 제공사실 및 그 시기 등에 관하여 다툼이 있는 경우에는 사업자가 이를 증명합니다. 

⑥ 사업자는 제2항제1호에 따라 청약철회등이 불가능한 경우에는 「콘텐츠산업진흥법」 제28조에 따른 「콘텐츠회원보호지침」에 따라 다음 각 호의 어느 하나에 해당하는 조치를 취합니다. 

1. 청약철회등이 불가능하다는 사실의 표시 

2. 시용상품의 제공 

3. 디지털콘텐츠의 한시적 또는 일부 이용 허용 

4. 제1호부터 제3호까지의 방법으로 시험 사용 상품 등을 제공하기 곤란한 경우에 디지털콘텐츠에 관한 정보 제공 

제34조(이용사업자의 청약철회등)   ① 사업자와 디지털콘텐츠에 관한 계약을 체결한 이용사업자는 다음 각 호의 기간(거래당사자가 다음 각 호의 기간보다 긴 기간으로 약정한 경우에는 그 기간을 말합니다) 이내에 그 계약에 대해 청약철회등을 할 수 있습니다. 
1. 제24조에 따른 계약서를 받은 날부터 7일. 다만, 계약서를 받은 때보다 디지털콘텐츠의 제공이 늦게 이루어진 경우에는 디지털콘텐츠를 제공받거나 디지털콘텐츠의 제공이 시작된 날부터 7일 

2. 제24조에 따른 계약서를 받지 아니한 경우, 사업자의 주소 등이 적혀 있지 아니한 계약서를 받은 경우 또는 사업자의 주소 변경 등의 사유로 제1호의 기간에 청약철회등을 할 수 없는 경우에는 사업자의 주소를 안 날 또는 알 수 있었던 날부터 7일 

3. 거짓 또는 과장된 사실을 알리거나 기만적 방법을 사용하여 이용사업자의 청약철회등을 방해하는 행위 또는 청약철회등을 방해할 목적으로 주소, 전화번호, 인터넷도메인 이름 등을 변경하거나 폐지하는 행위가 있는 경우에는 그 방해 행위가 종료한 날부터 7일 

② 이용사업자는 다음 각 호의 어느 하나에 해당하는 경우에는 사업자의 의사에 반하여 제1항에 따른 청약철회등을 할 수 없습니다. 다만, 사업자가 제6항에 따른 조치를 하지 아니하는 경우에는 제2호부터 제5호까지에 해당하는 경우에도 청약철회등을 할 수 있습니다. 

1. 이용사업자에게 책임이 있는 사유로 디지털콘텐츠가 멸실되거나 훼손된 경우. 다만, 디지털콘텐츠의 내용을 확인하기 위하여 포장 등을 훼손한 경우는 제외합니다. 

2. 이용사업자의 사용 또는 일부 소비로 디지털콘텐츠의 가치가 현저히 감소한 경우 

3. 시간이 지나 다시 판매하기 곤란할 정도로 디지털콘텐츠의 가치가 현저히 감소한 경우 

4. 복제가 가능한 디지털콘텐츠의 포장을 훼손한 경우 

5. 디지털콘텐츠의 제공이 개시된 경우. 다만, 가분적 디지털콘텐츠의 경우에는 제공이 개시되지 아니한 부분에 대하여는 그러하지 않습니다. 

6. 이용사업자의 주문에 따라 개별적으로 생산되는 디지털콘텐츠 또는 이와 유사한 디지털콘텐츠에 대하여 청약철회등을 인정하는 경우 사업자에게 회복할 수 없는 중대한 피해가 예상되는 경우로서 사전에 해당 거래에 대하여 별도로 그 사실을 고지하고 이용사업자의 서면(전자문서를 포함합니다)에 의한 동의를 받은 경우 

③ 이용사업자는 제1항 및 제2항에도 불구하고 디지털콘텐츠의 내용이 표시ㆍ광고의 내용과 다르거나 계약내용과 다르게 이행된 경우에는 그 디지털콘텐츠를 제공받은 날부터 3개월 이내, 그 사실을 안 날 또는 알 수 있었던 날부터 30일 이내에 청약철회등을 할 수 있습니다. 

④ 제1항 또는 제3항에 따른 청약철회등을 서면으로 하는 경우에는 그 의사표시가 적힌 서면을 발송한 날에 그 효력이 발생합니다. 

⑤ 제1항부터 제3항까지를 적용할 때 디지털콘텐츠의 훼손에 대하여 이용사업자의 책임이 있는지 여부, 디지털콘텐츠에 관한 계약이 체결된 사실 및 그 시기, 디지털콘텐츠의 제공사실 및 그 시기 등에 관하여 다툼이 있는 경우에는 사업자가 이를 증명합니다. 

⑥ 사업자는 제2항제2호부터 제5호까지에 따라 청약철회등이 불가능한 경우에는 「콘텐츠산업진흥법」 제28조에 따른 「콘텐츠회원보호지침」에 따라 다음 각 호의 어느 하나에 해당하는 조치를 취합니다. 

1. 청약철회등이 불가능하다는 사실의 표시 

2. 시용상품의 제공 

3. 디지털콘텐츠의 한시적 또는 일부 이용 허용 

4. 제1호부터 제3호까지의 방법으로 시험 사용 상품 등을 제공하기 곤란한 경우에 디지털콘텐츠에 관한 정보 제공 

제35조(회원의 청약철회등의 효과)   ① 제32조부터 제34조까지의 청약철회등이 있는 경우에 회원는 제공받은 디지털콘텐츠등을 반환하지 않습니다. 
② 사업자는 회원가 제32조부터 제34조까지에 따라 청약철회등을 한 날부터 3영업일 이내에 회원로부터 지급받은 대금을 환급합니다. 이 경우, 사업자가 회원에게 대금 환급을 지연한 때에는 그 지연기간에 대하여 연 15%의 이율을 곱하여 산정한 지연이자(이하 "지연배상금"이라 합니다)를 지급합니다. 

③ 사업자는 제2항에 따라 대금을 환급할 때 회원가 「여신전문금융업법」 제2조제3호에 따른 신용카드 등으로 대금을 지급한 경우에는 지체 없이 해당 결제수단을 제공한 사업자(이하 "결제업자"라 합니다)에게 대금 청구를 정지하거나 취소하도록 요청합니다. 이 경우, 사업자가 결제업자로부터 해당 대금을 이미 받은 때에는 지체 없이 그 대금을 결제업자에게 환급하고, 그 사실을 회원에게 알립니다. 

④ 제3항 단서에 해당하는 경우, 사업자 중 환급을 지연하여 회원가 대금을 결제하게 한 때에 사업자는 그 지연기간에 대한 지연배상금을 회원에게 지급합니다. 

⑤ 회원는 사업자가 제3항 단서에도 불구하고 정당한 사유 없이 결제업자에게 대금을 환급하지 아니하는 경우에는 결제업자에게 사업자에 대한 다른 채무와 사업자로부터 환급받을 금액을 상계(相計)할 것을 요청할 수 있습니다. 이 경우, 결제업자는 사업자에 대한 다른 채무와 상계할 수 있습니다. 

⑥ 회원는 결제업자가 제5항에 따른 상계를 정당한 사유 없이 게을리한 경우에는 결제업자에 대하여 대금의 결제를 거부할 수 있습니다. 이 경우, 사업자와 결제업자는 그 결제 거부를 이유로 그 회원를 약정한 기일까지 채무를 변제하지 아니한 자로 처리하는 등 회원에게 불이익을 주는 행위를 하지 않습니다. 

⑦ 제1항의 경우 사업자는 이미 디지털콘텐츠등이 일부 사용되거나 일부 소비된 경우에는 그 디지털콘텐츠등의 일부 사용 또는 일부 소비에 의하여 회원가 얻은 이익 또는 그 디지털콘텐츠등의 제공에 든 비용에 상당하는 금액으로서 다수의 동일한 가분물로 구성된 디지털콘텐츠등의 경우에는 회원의 일부 소비로 인하여 소비된 부분의 제공에 든 비용을 회원에게 청구할 수 있습니다. 

⑧ 제32조부터 제34조까지에 따른 청약철회등의 경우, 사업자는 회원에게 청약철회등을 이유로 위약금이나 손해배상을 청구하지 않습니다. 

      제4장 피해구제 및 계약의 해제ㆍ해지

제36조(디지털콘텐츠등의 하자와 담보책임)   ① 사업자가 제공한 디지털콘텐츠등에 하자가 있는 경우로서 회원가 계약의 목적을 달성할 수 있는 경우, 회원는 사업자에게 다음 각 호에서 정한 권리를 행사할 수 있습니다. 다만, 회원가 디지털콘텐츠등에 하자가 있다는 사실을 알았거나 과실로 알지 못한 경우에는 그러하지 않습니다. 
1. 하자없는 디지털콘텐츠등의 제공청구권 

2. 하자보수청구권 

3. 대금감액청구권 

② 사업자가 제공한 디지털콘텐츠등에 하자가 있는 경우로서 회원가 계약의 목적을 달성할 수 없는 경우에 회원는 사업자에게 다음 각 호에서 정한 권리를 행사할 수 있습니다. 다만, 회원가 디지털콘텐츠등에 하자가 있다는 사실을 알았거나 과실로 알지 못한 경우에는 그러하지 않습니다. 

1. 하자없는 디지털콘텐츠등의 제공청구권 

2. 하자보수청구권 

3. 대금감액청구권 

4. 해제권 또는 해지권 

③ 회원는 제1항 또는 제2항에 따른 권리 행사 외에 이로 인해 발생한 손해에 대한 배상을 청구할 수 있습니다. 이 경우, 사업자는 고의 또는 과실이 없음을 증명하더라도 그 책임을 부담합니다. 

④ 제1항부터 제3항까지에서 정한 사항 외에 디지털콘텐츠의 하자담보책임에 대해서는 「콘텐츠회원보호지침」에서 정한 바에 따릅니다. 

제37조(사업자의 채무불이행과 책임)   ① 디지털콘텐츠의 계속적 이용계약의 경우에 사업자가 디지털콘텐츠등의 이용중지 또는 장애에 대하여 사전에 알리지 않은 경우, 회원의 피해구제 등은 다음 각 호에서 정한 바에 따릅니다. 다만, 회원의 책임있는 사유로 인하여 디지털콘텐츠등의 이용이 중지되거나 장애가 발생한 경우, 그 시간은 중지 또는 장애시간에 포함되지 않습니다. 
1. 1개월 동안의 중지 또는 장애발생 누적시간이 72시간을 초과한 경우 : 회원는 계약을 해제 또는 해지할 수 있으며, 사업자는 미이용기간을 포함한 잔여기간에 대한 이용료의 환급 및 손해배상을 합니다. 다만, 사업자가 고의 또는 과실 없음을 증명한 경우에는 손해배상책임을 부담하지 않습니다. 

2. 사업자의 책임있는 사유로 인해 중지 또는 장애가 발생하고, 그 중지 또는 장애시간이 1개월 동안 72시간 이하인 경우 : 사업자는 중지 또는 장애시간의 3배를 무료로 연장합니다. 

3. 불가항력 또는 제3자의 불법행위 등으로 인해 중지 또는 장애가 발생하고, 그 중지 또는 장애시간이 1개월 동안 72시간 이하인 경우 : 사업자는 중지 또는 장애시간만큼 무료로 이용기간을 연장합니다. 

② 사업자가 디지털콘텐츠등의 이용중지 또는 장애에 대하여 사전에 알린 경우, 회원의 피해구제 등은 다음 각 호에서 정한 바에 따릅니다. 다만, 서비스 개선을 목적으로 한 설비 점검 및 보수의 경우에 1개월을 기준으로 최대 24시간은 중지 또는 장애시간에 포함되지 않습니다. 

1. 1개월을 기준으로 중지 또는 장애시간이 10시간을 초과하는 경우 : 사업자는 10시간과 이를 초과한 시간의 2배의 시간만큼 이용기간을 무료로 연장합니다. 

2. 1개월을 기준으로 중지 또는 장애시간이 10시간 이하인 경우 : 사업자는 중지 또는 장애시간만큼 무료로 연장합니다. 

③ 제1항 및 제2항을 적용함에 있어서 사전고지는 중지 또는 장애시점을 기준으로 24시간 이전에 알린 것으로 한정합니다. 다만, 불가항력적인 사유로 인해 사전에 고지할 수 없는 경우에는 그러하지 않습니다. 

제38조(사업자의 채무불이행과 해제)   ① 사업자가 디지털콘텐츠등의 이용계약에서 정한 시기까지 디지털콘텐츠등을 제공하지 않은 경우에 회원는 상당한 기간을 정하여 디지털콘텐츠등의 제공을 요구할 수 있습니다. 
② 사업자가 제1항에 따른 기간 내에 디지털콘텐츠등을 제공하지 않은 경우에 회원는 디지털콘텐츠등의 이용계약을 해제 또는 해지할 수 있습니다. 다만, 다음 각 호의 어느 하나에 해당하는 경우, 회원는 제1항에 따른 재요구를 하지 않고 디지털콘텐츠등의 이용계약을 해제 또는 해지할 수 있습니다. 

1. 사업자가 디지털콘텐츠등의 제공을 거절한 경우 

2. 디지털콘텐츠등이 계약에서 정한 시기에 제공되어야 만이 계약의 목적을 달성할 수 있는 경우 

③ 디지털콘텐츠등의 제공시기와 관계없이 사업자의 책임있는 사유로 디지털콘텐츠등의 제공이 불가능하게 된 경우에 회원는 디지털콘텐츠등의 이용계약을 해제할 수 있습니다. 

④ 사업자가 디지털콘텐츠등을 제공하였지만, 계약내용에 적합하지 않게 디지털콘텐츠등을 제공한 경우에 회원는 다음 각 호에서 정한 바에 따라 권리를 행사할 수 있습니다. 

1. 계약의 목적을 달성할 수 있는 경우 

가. 디지털콘텐츠등의 보수 등이 가능한 경우 : 제36조제1항 각 호에서 정한 권리 

나. 디지털콘텐츠등의 보수 등이 불가능한 경우 : 제36조제1항제1호 또는 제3호에서 정한 권리 

2. 계약의 목적을 달성할 수 없는 경우 

가. 디지털콘텐츠등의 보수 등이 가능한 경우 : 제36조제2항 각 호에서 정한 권리 

나. 디지털콘텐츠등의 보수 등이 불가능한 경우 : 제36조제2항제1호 또는 제4호에서 정한 권리 

⑤ 회원는 제1항부터 제4항까지에 따른 권리를 행사하는 것 외에 사업자에게 이로 인해 발생한 손해에 대한 배상을 청구할 수 있습니다. 다만, 사업자가 고의 또는 과실 없음을 증명한 경우에 그러하지 않습니다. 

제39조(회원의 대금미지급과 책임등)   ① 회원가 이 계약에서 정한 기한까지 디지털콘텐츠등의 대금을 지급하지 않았을 경우에 사업자는 상당한 기간을 정하여 대금지급을 청구할 수 있습니다. 
② 회원는 제1항에 따른 기간 내에 디지털콘텐츠등의 대금과 이에 대한 지연이자를 사업자에게 지급합니다. 이 경우, 불가항력적인 사유가 있는 경우를 제외하고, 회원는 대금미지급에 대해 고의 또는 과실없음을 증명하여 지연이자의 지급을 거절할 수 없습니다. 

③ 회원가 제1항에 따른 기간 내에 디지털콘텐츠등의 대금 및 지연이자를 지급하지 않았을 경우에 사업자는 디지털콘텐츠등의 이용계약을 해제 또는 해지할 수 있습니다. 다만, 회원가 사전에 대금지급을 거절한 경우에 사업자는 제1항에 따른 재요구를 하지 않고 디지털콘텐츠등의 이용계약을 해제 또는 해지할 수 있습니다. 

제40조(계속거래와 소비자의 해지 및 효과)   ① 법률에서 다르게 정한 경우를 제외하고, 사업자와 계속거래를 체결한 소비자는 계약기간 중 언제든지 그 계약을 해지할 수 있습니다. 
② 사업자는 자신의 책임이 없는 사유로 계속거래가 해지된 경우에 소비자에게 해지로 발생하는 손실을 현저하게 초과하는 위약금을 청구하지 않으며, 가입비나 그 밖에 명칭에 상관없이 실제 제공된 디지털콘텐츠등의 대가를 초과하여 수령한 대금의 환급을 부당하게 거부하지 않습니다. 

③ 제1항에 따라 계속거래가 해지된 경우에 소비자는 반환할 수 있는 디지털콘텐츠를 사업자에게 반환할 수 있으며, 사업자는 대금 환급 또는 위약금 경감 등의 조치를 취합니다. 

④ 사업자는 자신의 책임이 없는 사유로 계약이 제1항에 따라 해지된 경우에 소비자로부터 받은 디지털콘텐츠등의 대금(디지털콘텐츠가 반환된 경우 환급하여야 할 금액을 포함합니다)이 이미 제공한 디지털콘텐츠등의 대금에 위약금을 더한 금액보다 많으면 그 차액을 소비자에게 환급합니다. 이 경우, 환급이 3영업일 이상 지연되는 경우에는 그 지연기간에 대한 지연배상금을 더하여 환급합니다. 

제41조(해제 또는 해지의 효과)   ① 제32조부터 제34조까지를 제외하고, 디지털콘텐츠등의 이용계약이 해제된 경우에 사업자 또는 회원는 다음 각 호에서 정한 바에 따라 원상회복의무를 부담합니다. 
1. 사업자는 소비자로부터 받은 대금을 환급합니다. 이 경우, 그 대금을 받은 날로부터 환급할 때까지 연 6%(이 보다 높은 이자율을 약정한 경우에는 그 약정이자율)를 곱하여 산정한 이자를 지급합니다. 

2. 회원는 사업자로부터 제공받은 디지털콘텐츠를 삭제하고, 자신 또는 제3자가 이용할 수 없게 조치합니다. 다만, 사업자가 디지털콘텐츠를 삭제하거나 이용할 수 없게 조치한 경우에는 그러하지 않습니다. 

3. 회원는 사업자로부터 제공받은 디지털콘텐츠등을 이용한 경우에 그에 따라 얻은 이익을 사업자에게 반환합니다. 

② 제40조를 제외하고, 디지털콘텐츠등의 이용계약이 해지된 경우에 사업자 또는 회원는 다음 각 호에서 정한 바에 따른 의무를 부담합니다. 

1. 사업자는 잔여기간에 대한 대금을 회원에게 환급합니다. 

2. 회원는 사업자가 제공한 디지털콘텐츠를 삭제하고, 자신 또는 제3자가 이용할 수 없게 조치합니다. 다만, 사업자가 디지털콘텐츠를 삭제하거나 이용할 수 없게 조치한 경우에는 그러하지 않습니다. 

③ 다른 합의가 있는 경우를 제외하고, 제1항 또는 제2항에 따른 의무는 동시에 이행합니다. 

제42조(손해배상책임)   ① 회원 또는 사업자가 사이버몰 또는 디지털콘텐츠등 이용계약을 위반하여 상대방에게 손해를 끼친 경우에 배상합니다. 다만, 손해배상책임을 지는 자가 고의 또는 과실 없음을 증명한 경우에는 그러하지 않습니다. 
② 제1항에도 불구하고, 이 약관 또는 법령에서 다르게 정한 경우에는 그에 따릅니다. 

제43조(소비자의 손해배상책임 제한) 소비자에게 책임이 있는 사유로 디지털콘텐츠등의 판매에 관한 계약이 해제된 경우, 사업자가 소비자에게 청구하는 손해배상액은 다음 각 호의 구분에 따라 정한 금액에 대금미납에 따른 지연배상금을 더한 금액을 초과할 수 없습니다.   
1. 제공된 디지털콘텐츠의 삭제 등에 따라 회원가 더 이상 이용할 수 없는 경우: 삭제된 디지털콘텐츠의 통상 사용료 또는 그 사용으로 통상 얻을 수 있는 이익에 해당하는 금액 

2. 제공된 디지털콘텐츠등의 삭제 등이 이루어지지 않은 경우: 그 디지털콘텐츠등의 판매가액에 해당하는 금액 

제44조(대금환급등의 방법)   ① 사업자가 회원에게 대금의 전부 또는 일부를 환급할 경우에 회원가 대금을 지급한 방법으로 환급합니다. 
② 제1항에서 정한 방법으로 대금의 전부 또는 일부를 환급할 수 없는 경우, 사업자는 회원가 선택하는 방법으로 환급합니다. 

③ 사업자는 회원가 지급한 대금 중 일부를 손해배상금 또는 이용금액 등으로 공제할 경우에 복수의 지급수단 중 회원가 선택한 지급수단에서 공제하며, 회원의 선택이 없는 경우에 회원에게 유리한 방법으로 공제합니다. 

      제5장 분쟁해결 및 재판관할

제45조(분쟁해결)   ① 사업자와 회원간 사이버몰 또는 디지털콘텐츠등의 이용계약과 관련하여 분쟁이 발생한 경우에 상호 협의하여 분쟁을 해결합니다. 
② 제1항에 따라 분쟁이 해결되지 않은 경우에 사업자 또는 회원는 「저작권법」에 따른 한국저작권위원회, 「콘텐츠산업진흥법」에 따른 콘텐츠분쟁조정위원회, 「전자문서 및 전자거래기본법」에 따른 전자문서ㆍ전자거래분쟁조정위원회, 「소비자기본법」에 따른 소비자분쟁조정위원회 등에 조정을 신청할 수 있습니다. 

③ 제1항에 따라 분쟁이 해결되지 않은 경우에 사업자 또는 회원는 「중재법」에 따른 중재를 통해 분쟁을 해결할 수 있습니다. 이 경우, 사업자 또는 회원가 중재를 신청하기 위해 상대방과 서면으로 중재합의를 합니다. 

제46조(재판관할)   ① 사업자와 소비자간 사이버몰 또는 디지털콘텐츠등의 이용계약에 관한 소는 소 제기 당시 소비자의 주소를 관할하는 지방법원의 전속관할로 하고, 주소가 없는 경우에는 거소(居所)를 관할하는 지방법원의 전속관할로 합니다. 다만, 소 제기 당시 소비자의 주소 또는 거소가 분명하지 아니한 경우에 관할법원은「민사소송법」에 따라 결정합니다. 
② 사업자와 이용사업자간 사이버몰 또는 디지털콘텐츠등의 이용계약에 관한 소는 사업자 또는 이용사업자의 주소를 관할하는 지방법원에 제기하며, 주소가 없는 경우에는 거소(居所)를 관할하는 지방법원에 제기합니다. 다만, 소 제기 당시 사업자 및 이용사업자의 주소 또는 거소가 분명하지 아니한 경우에 관할법원은「민사소송법」에 따라 결정합니다. 

부칙
1. 이 약관은 2022년 12월 23일 부터 적용됩니다.
`;

const VllageService = () => {
    return <div id="vllage-terms">
    <br/>
    <br/>
    <br/>
    <br/>
    <h1>커뮤니티 이용약관</h1>
    <pre className="terms-pre">
        {communityText}
    </pre>
    <br/>
    <br/>
    <h1>인터넷 쇼핑몰 (상점) 이용약관</h1>
    <pre className="terms-pre">
        {shopText}
    </pre>
    <br/>
    <br/>
    <h1>디지털 콘텐츠 (상점) 이용약관</h1>
    <pre className="terms-pre">
        {contentText}
    </pre>
  </div>;};

export default VllageService;
