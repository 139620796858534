import {computed, makeObservable, observable, runInAction} from "mobx";

const SLIDES = ['home', 'about', 'services', 'clients'];

class PageSlideScrollStore {
    current = null;
    active = null;

    currentWheelTime = Date.now();
    scrolled = false;

    isPortfolio = null;
    isContact = null;
    isVirtualOffice = null;

    constructor() {
        window.addEventListener('wheel', e => this.onWheel(e), {passive: false});
        this.update();

        makeObservable(this, {
            current: observable,
            isPortfolio: observable,
            isContact: observable,
            isVirtualOffice: observable,
            isHome: computed,
            isAbout: computed,
            isServices: computed,
            isClients: computed
        });
    }

    onWheel(e) {
        if (!this.active) {
            return;
        }
        if (Math.abs(e.deltaY) > 10 && Date.now() - this.currentWheelTime > 1000) {
            if (this.scrolled) {
                this.scrolled = false;
                e.preventDefault();
                return;
            }
            this.currentWheelTime = Date.now();

            const toUp = e.deltaY < 0
            const toDown = e.deltaY > 0

            const scroll = (id) => {
                const top = document.getElementById(id)?.getBoundingClientRect()?.top + (window.scrollY || window.pageYOffset || 0);
                window.scrollTo({top, left: 0, behavior: 'smooth'});
                e.preventDefault();
                this.scrolled = true;
            };

            if (toDown && this.current === SLIDES[SLIDES.length - 1]) {
                scroll('main-footer')
            }

            if (!SLIDES.some((slide, idx) => {
                const isFirst = idx === 0;
                const isLast = idx === SLIDES.length - 1;

                if (this.isIn(slide, true)) {
                    if (toUp && !isFirst) {
                        scroll(SLIDES[idx - 1]);
                    }
                    if (toDown) {
                        if (!isLast) {
                            scroll(SLIDES[idx + 1]);
                        } else if (!this.isIn(slide)) {
                            scroll(SLIDES[idx]);
                        }
                    }
                    return true;
                }
                return false;
            })) {
                if (toUp) {
                    scroll(SLIDES[SLIDES.length - 1]);
                }
            }
        } else {
            e.preventDefault();
        }
    }

    update() {
        this.active = window.location.pathname === '/' && window.innerWidth >= 1440;

        runInAction(() => {
            this.isPortfolio = ['/portfolio/zepeto', '/portfolio/gather-town'].includes(window.location.pathname);
            this.isContact = window.location.pathname === '/contact';
            this.isVirtualOffice = window.location.pathname === '/virtual-office';
        });

        if (!SLIDES.some(slide => {
            if (this.isIn(slide)) {
                runInAction(() => {
                    this.current = slide;
                });
                return true;
            }
            return false;
        })) {
            runInAction(() => {
                this.current = null;
            });
        }
        requestAnimationFrame(() => this.update());
    }

    isIn(slide, fit) {
        const y = document.getElementById(slide)?.getBoundingClientRect()?.top;
        const height = document.body.clientHeight;
        if (fit) {
            return y >= 0 && y < height;
        }
        return y >= -height / 2 && y < height / 2;
    }

    get isHome() {
        return this.current === 'home';
    }

    get isAbout() {
        return this.current === 'about';
    }

    get isServices() {
        return this.current === 'services';
    }

    get isClients() {
        return this.current === 'clients';
    }

    static _instance;

    static getInstance() {
        if (!this._instance) this._instance = new PageSlideScrollStore();
        return this._instance;
    }
}

export default PageSlideScrollStore;
