import PortfolioItem from "../../common/item/PortfolioItem";

const GatherTownPortfolioItems = () => {
    return (
        <>
            <PortfolioItem
                title={"월드맵 기획 / 구성"}
                content={"기업 브랜드의 톤 앤 매너에 맞게 최적화된 가상공간을 기획합니다."}
            />
            <PortfolioItem
                title={"오퍼레이터 파견 및 기술지원"}
                content={
                    <>
                        행사 진행시 오퍼레이터를 파견하여 원활한 행사진행이 가능하도록<br/>
                        기술지원팀을 파견해드립니다.
                    </>
                }
            />
        </>
    );
};

export default GatherTownPortfolioItems;
