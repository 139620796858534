import "./TextareaBox.css";
import classNames from "classnames";

function TextareaBox({className, value, placeholder, onChange}) {
    const handleChange = (event) => {
        onChange(event.target.value);
    };

    return (
        <div className={classNames('textarea-box', className, {hasValue: !!value})}>
            <textarea
                value={value}
                placeholder={placeholder}
                onChange={handleChange}
            />
            <div className={'title'}>
                {placeholder}
            </div>
        </div>
    );
}

export default TextareaBox;
