import "./AboutContent.css";

const AboutContent = () => {
    return (
        <div id={"about-content"}>
            <div className={"headlines"}>
                <h2>
                    가상세계로<br/>
                    가는 첫걸음.
                </h2>
                <p>
                    제 2의 세계인 메타버스.<br/>
                    그 속에서 발생하는 모든 일에 <span>데브메이트</span>가 함께합니다.
                </p>
            </div>
            <div className={"quotes"}>
                <h3 className={"eng"}>
                    Solve it<br/>
                    at once with us
                </h3>
                <p>
                    저희 데브메이트는 국내 유일 올인원 메타버스 기업입니다.<br/>
                    메타버스 플랫폼에 관련된 모든 서비스를 제공해드리며,<br/>
                    다수의 경험과 전략이 최고의 결과물을 만들어냅니다.
                </p>
                <h3 className={"eng"}>
                    Solve it<br/>
                    at once with us
                </h3>
                <p>
                    타 기업과 비교해 높은 퀄리티와 합리적인 가격을<br/>
                    약속드립니다. 트렌디함과 전문성을 동시에 갖춘,<br/>
                    저희는 데브메이트입니다.
                </p>
            </div>
        </div>
    )
};

export default AboutContent;
