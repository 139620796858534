import "./ContactFAQ.css";
import ContactFAQItem from "./item/ContactFAQItem";

const ContactFAQ = () => {
    return (
        <div id={"contact-faq"} className={"content"}>
            <header className={"eng"}>FAQ</header>
            <article>
                <ContactFAQItem
                    title={"월드맵 구축에 필요한 비용과 시간은 어느정도인가요?"}
                    content={
                        <>
                            공간 기획에 따라 폭넓게 진행되고 있습니다.<br/>
                            예산과 기간은 최대한 맞춰드리고 있으므로, 편하게 문의 주세요 !
                        </>
                    }
                />
                <ContactFAQItem
                    title={"메타버스 행사를 진행하고 싶은데 메타버스에 대해 잘 몰라도 진행할 수 있나요?"}
                    content={
                        <>
                            데브메이트는 메타버스 행사 대행 서비스를 제공하고 있으며<br/>
                            당일 행사 기획, 공간 기획, 공간 구축까지 원스톱으로 진행합니다.
                        </>
                    }
                />
                <ContactFAQItem
                    title={"메타버스를 통한 홍보 비디오도 제작하시나요?"}
                    content={
                        <>
                            데브메이트는 영상 콘티 제작, 영상 촬영, 편집까지 메타버스를 통한 홍보 비디오를 제작하고 있습니다.
                        </>
                    }
                />
                <ContactFAQItem
                    title={"ZEPETO Build it과 World의 차이는 무엇인가요?"}
                    content={
                        <>
                            제페토 빌드잇은 네이버 Z에서 기본적으로 제공해주는 도구로, 오브젝트 퀄리티가 월드보다는 낮습니다.<br/>
                            제페토 월드의 경우 Unity 게임 엔진으로 제작되어 시중 게임 정도의 퀄리티로 월드맵 제작이 가능합니다.<br/>
                            또한, 추가적으로 게임이나 콘텐츠 등을 제페토에 넣을 수 있습니다.
                        </>
                    }
                />
                <ContactFAQItem
                    title={"ZEPETO, Gather Town, ROBLOX의 차이는 무엇인가요?"}
                    content={
                        <>
                            제페토는 3D 플랫폼으로 네이버에서 제작한 글로벌 메타버스 플랫폼입니다.<br/>
                            월 2억명 정도의 사용자가 방문하고 있어 홍보 목적의 맵 제작에 유리합니다.<br/>
                            다만, 최대 동시 접속 인원이 16명이라 대규모 행사를 진행하기에는 부적합합니다. ( 16명 이후 접속자는 새로운 월드가 생성됩니다. )<br/>
                            게더타운은 2D 플랫폼으로 최대 500명까지 동시 접속 가능하며, 줌의 모든 기능을 담고 있어 행사 목적의 맵 제작에 유리합니다.<br/>
                            로블록스는 제페토보다 고도화된 게이미피케이션 기능을 가지고 있어 게임 등 홍보 목적의 맵 제작에 유리합니다.
                        </>
                    }
                />
                <ContactFAQItem
                    title={"사후 관리는 어떻게 진행되나요?"}
                    content={
                        <>
                            맵을 구축하신 이후에 월 유지보수 계약을 체결하시면 모든 플랫폼에 대해 장애 발생 시 즉각 대응해드리고 있습니다.<br/>
                            또한, 월마다 간단한 오브젝트의 수정이나 추가를 진행해드리고 있습니다.
                        </>
                    }
                />
            </article>
        </div>
    );
};

export default ContactFAQ;
