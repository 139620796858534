import { useEffect, useState } from "react";
import { ref, onValue } from "firebase/database";
import { firebaseDB } from "../../../../../firebase";
import Popup from "../../Popup";
import PopupTitle from "../../PopupTitle";
import PortfolioPopupContent from "../PortfolioPopupContent";
import PortfolioPopupContentItem from "../PortfolioPopupContentItem";

const PortfolioSamplePopup = ({ itemKey, type = "" }) => {
  const [portfolio, setPortfolio] = useState({});
  const { name = '', portfolio: folio = [], extras = []} = portfolio;

  useEffect(() => {
    console.log(itemKey, type);
    if (!!type) {
      const dataRef = ref(firebaseDB, `list/${type}/${itemKey}`);
      onValue(
        dataRef,
        (snapshot) => {
          const data = snapshot.val();
          console.log(data);
          setPortfolio(data);
        },
        {
          onlyOnce: true,
        }
      );
    }
  }, []);

  return (
    <Popup>
      <PopupTitle>
        {type.toUpperCase()}
        <br />
        {name}
      </PopupTitle>
      <PortfolioPopupContent photos={folio || []}>
        {extras.map((item) => (
          <PortfolioPopupContentItem title={item?.name}>
            {item?.type === "link" ? <a href={item?.value} target="_blank" rel="noreferrer">{item?.value}</a> : item?.value}
          </PortfolioPopupContentItem>
        ))}
        {/* <PortfolioPopupContentRow>
                    <PortfolioPopupContentItem title={"참여 기간"}>
                        2021년 8월 - 2021년 9얼
                    </PortfolioPopupContentItem>
                    <PortfolioPopupContentItem title={"프로젝트 설명"}>
                        ZEPETO 빌드잇을 활용하여 아래와 같은 맵<br/>
                        구성을 완료했습니다.<br/>
                        · 항만 (컨테이너, 크레인 등을 제작 후 구성)<br/>
                        · 관저 (관저 내부 모델링을 이용하여 제작)<br/>
                        ZEPETO 심사 절차 진행 후 월드맵 업로드 완료.<br/>
                        ZEPETO 플랫폼 내에서 전세계인들이 현재도 방문하고 있습니다.
                    </PortfolioPopupContentItem>
                </PortfolioPopupContentRow>
                <PortfolioPopupContentRow>
                    <PortfolioPopupContentItem title={"고객사"}>
                        부산항만공사
                    </PortfolioPopupContentItem>
                    <PortfolioPopupContentItem title={"견적 금액"}>
                        500만원 이상
                    </PortfolioPopupContentItem>
                    <PortfolioPopupContentItem title={"기술 키워드"}>
                        #ZEPETO #증강현실
                    </PortfolioPopupContentItem>
                </PortfolioPopupContentRow> */}
      </PortfolioPopupContent>
    </Popup>
  );
};

export default PortfolioSamplePopup;
