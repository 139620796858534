import { useEffect, useState } from "react";
import { ref, onValue } from "firebase/database";
import { firebaseDB } from "../../../../../firebase";
import PortfolioDetailSlide from "../common/PortfolioDetailSlide";
import PortfolioDetailItem from "../common/item/PortfolioDetailItem";

const ZepetoPortfolioDetailSlide = () => {
  const [portfolio, setPortfolio] = useState([]);

  useEffect(() => {
    const listRef = ref(firebaseDB, "list/zepeto");
    onValue(
      listRef,
      (snapshot) => {
        const data = snapshot.val();
        if (data?.length) {
          setPortfolio(data);
        }
      },
      {
        onlyOnce: true,
      }
    );
  }, []);

  return (
    <PortfolioDetailSlide type={"zepeto"}>
      {portfolio.map((item, index) => (
        <PortfolioDetailItem
          title={item?.name}
          description={item?.description ? item?.description : "ZEPETO 월드맵"}
          photo={item?.thumbnail}
          type={"zepeto"}
          index={index}
          key={index}
        />
      ))}
    </PortfolioDetailSlide>
  );
};

export default ZepetoPortfolioDetailSlide;
