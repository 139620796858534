import "./ClientsCompany.css";
import logo_ajou from "./logos/logo_ajou.png";
import logo_bpa from "./logos/logo_bpa.png";
import logo_dawoo from "./logos/logo_dawoo.png";
import logo_hana from "./logos/logo_hana.png";
import logo_hyundai from "./logos/logo_hyundai.png";
import logo_snu from "./logos/logo_snu.png";
import logo_spep from "./logos/logo_spep.png";
import ClientsCompanyRowWrap from "./row/ClientsCompanyRowWrap";
import ClientsCompanyButtons from "./buttons/ClientsCompanyButtons";

const img_row_1 = [logo_dawoo, logo_hana, logo_snu, logo_ajou];
const img_row_2 = [logo_spep, logo_bpa, logo_hyundai];

const ClientsCompany = () => {
    return (
        <div id={"clients-company"}>
            <div className={"wrap"}>
                <ClientsCompanyRowWrap images={img_row_1} speed={2}/>
                <ClientsCompanyRowWrap images={img_row_2} speed={1.5}/>
            </div>
            <ClientsCompanyButtons/>
        </div>
    );
};

export default ClientsCompany;
