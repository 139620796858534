import PageSlide from "../PageSlide";
import "./ClientsSlide.css";
import ClientsTitle from "./title/ClientsTitle";
import ClientsCompany from "./company/ClientsCompany";

const ClientsSlide = () => {
    return (
        <PageSlide id={"clients"}>
            <ClientsTitle/>
            <ClientsCompany/>
        </PageSlide>
    );
};

export default ClientsSlide;
