import PageSlideScrollStore from "../store/pageSlideScrollStore";
import {useEffect, useState} from "react";

const scrollStore = PageSlideScrollStore.getInstance();

export function useSlideEntered(slideId) {
    const [entered, setEntered] = useState(scrollStore.current === slideId);

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (scrollStore.current === slideId) {
                setEntered(true);
            }
        }, 1000 / 30)
        return () => clearInterval(intervalId);
    }, [slideId]);

    return entered;
}
