import classNames from "classnames";
import "./PortfolioPhotoBox.css";

const PortfolioPhotoBox = ({children, rightward}) => {
    return (
        <div className={classNames("portfolio-photo-box", {rightward})}>
            {children}
        </div>
    );
};

export default PortfolioPhotoBox;
