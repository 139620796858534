import ServiceCard from "./ServiceCard";
import background from "./background_service_card_3.png";

const ServiceCard3 = ({active}) => {
    return (
        <ServiceCard
            no={3}
            active={active}
            title={<>ZEPETO<br/>홍보 비디오<br/>제작</>}
            detailTitle={
                <>
                    ZEPETO 플랫폼 내에서  ZEPETO 드라마, 홍보<br/>
                    영상 등을  제작합니다.
                </>
            }
            detailContent={
                <>
                    MZ세대를 겨냥한<br/>
                    ZEPETO 캐릭터들과 함께  브랜드를 멋지게 홍보해보세요.
                </>
            }
            detailSmallTitle={
                <>
                    ZEPETO 플랫폼 내에서<br/>
                    ZEPETO 드라마, 홍보 영상 등을<br/>
                    제작합니다.
                </>
            }
            detailSmallContent={
                <>
                    MZ세대를 겨냥한 ZEPETO 캐릭터들과 함께<br/>
                    브랜드를 멋지게 홍보해보세요.
                </>
            }
            background={background}
            icon={(
                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6 12.1538V35.8462C6 37.0357 6.89543 38 8 38H22C25.3137 38 28 35.1071 28 31.5385V16.4615C28 12.8929 25.3137 10 22 10H8C6.89543 10 6 10.9643 6 12.1538Z" fill="#0D0C0C" fillOpacity="0.2"/>
                    <path d="M30.971 30.8736C30.3686 30.4844 30 29.7833 30 29.0267V20.1676C30 19.4648 30.3184 18.8062 30.8531 18.4031L38.8531 12.3724C40.1786 11.3731 42 12.3943 42 14.1369V34.196C42 35.8701 40.3041 36.9042 38.971 36.0429L30.971 30.8736Z" fill="#0D0C0C" fillOpacity="0.2"/>
                </svg>
            )}
        />
    );
};

export default ServiceCard3;
