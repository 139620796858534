import {observer} from "mobx-react-lite";
import {useCallback, useEffect, useState} from "react";
import MainDrawerStore from "./mainDrawerStore";
import "./MainDrawer.css";
import CloseButton from "../../common/button/close/CloseButton";
import PageSlideScrollStore from "../../store/pageSlideScrollStore";
import classNames from "classnames";

const drawerStore = MainDrawerStore.getInstance();
const scrollStore = PageSlideScrollStore.getInstance();

function cn(active) {
    return classNames("link eng", {active});
}

const MainDrawer = observer(() => {
    const [mounted, setMounted] = useState(false);

    const onClose = useCallback(() => {
        setMounted(false);
        setTimeout(() => {
            drawerStore.isOpened = false;
        }, 300);
    }, []);


    useEffect(() => {
        setMounted(true);
    }, []);

    return (
        <div id={"main-drawer-wrap"} className={classNames({mounted})}>
            <div className={"background"} onClick={onClose}/>
            <div className={"drawer"}>
                <CloseButton className={"close"} onClick={onClose}/>
                <a className={cn(scrollStore.isHome)} href={"/#home"} onClick={onClose}>HOME</a>
                <a className={cn(scrollStore.isAbout)} href={"/#about"} onClick={onClose}>ABOUT</a>
                <a className={cn(scrollStore.isServices)} href={"/#services"} onClick={onClose}>SERVICES</a>
                <a className={cn(scrollStore.isHome)} href={"/#clients"} onClick={onClose}>CLIENTS</a>
                <div className={cn(scrollStore.isPortfolio)}>PORTFOLIO</div>
                <a className={"sub-link eng"} href={"/portfolio/detail/zepeto"} onClick={onClose}>ZEPETO</a>
                <a className={"sub-link eng"} href={"/portfolio/detail/gather-town"} onClick={onClose}>GATHER TOWN</a>
                <a className={"sub-link eng"} href={"/portfolio/detail/zep"} onClick={onClose}>ZEP</a>
                <a className={"sub-link eng"} href={"/portfolio/detail/etc"} onClick={onClose}>ETC</a>
                <div className={cn(scrollStore.isContact)}>CONTACT</div>
                <a className={"sub-link eng"} href={"/contact#contact-process"} onClick={onClose}>PROCESS</a>
                <a className={"sub-link eng"} href={"/contact#contact-faq"} onClick={onClose}>FAQ</a>
                <a className={"sub-link eng"} href={"/contact#contact-form"} onClick={onClose}>CONTACT</a>
                <a className={cn(scrollStore.isVirtualOffice)} href={"/virtual-office"} onClick={onClose}>VIRTUAL OFFICE</a>
            </div>
        </div>
    );
})

export default MainDrawer;
