import "./InputBox.css";
import classNames from "classnames";

function InputBox({className, value, placeholder, onChange}) {
    const handleChange = (event) => {
        onChange(event.target.value);
    };

    return (
        <div className={classNames('input-box', className, {hasValue: !!value})}>
            <input
                value={value}
                placeholder={placeholder}
                onChange={handleChange}
            />
            <div className={'title'}>
                {placeholder}
            </div>
        </div>
    );
}

export default InputBox;
