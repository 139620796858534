import PageSlide from "../PageSlide";
import "./HomeSlide.css";
import HomeContent from "./content/HomeContent";

const HomeSlide = () => {
    return (
        <PageSlide id={"home"}>
            <HomeContent/>
        </PageSlide>
    );
}

export default HomeSlide;
