import {useEffect, useState} from "react";
import {useSlideEntered} from "./useSlideEntered";

export function useSlideAnimating(slideId) {
    const entered = useSlideEntered(slideId);
    const [animating, setAnimating] = useState(entered);

    useEffect(() => {
        if (entered) setAnimating(true);
    }, [entered]);

    return animating;
}
