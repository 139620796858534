import PortfolioSlide from "../common/PortfolioSlide";
import PageSlideTitle from "../../PageSlideTitle";
import ZepetoPortfolioPhotoBox from "./photo/ZepetoPortfolioPhotoBox";
import ZepetoPortfolioItems from "./items/ZepetoPortfolioItems";
import PortfolioMoreButton from "../common/more/PortfolioMoreButton";
import {useHistory} from "react-router-dom";

const ZepetoPortfolioSlide = () => {
    const history = useHistory();

    const handleMoreClick = () => {
        history.push('/portfolio/detail/zepeto');
    };

    return (
        <PortfolioSlide id={"portfolio-zepeto"}>
            <PageSlideTitle>
                Create your world<br/>
                with <span>ZEPETO</span>
            </PageSlideTitle>
            <ZepetoPortfolioItems/>
            <PortfolioMoreButton
                icon={(
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M4.033 15.967C8.46446 20.3985 14.7284 21.3194 18.0239 18.0239C21.3194 14.7284 20.3985 8.46446 15.967 4.033C11.5355 -0.398464 5.27162 -1.31935 1.97613 1.97613C-1.31935 5.27162 -0.398464 11.5355 4.033 15.967ZM6.10837 7.12963C6.10837 7.88168 6.71802 8.49133 7.47007 8.49133H9.40248L6.5072 11.3866C6.11776 11.776 6.00126 12.3617 6.21202 12.8706C6.42279 13.3794 6.91931 13.7112 7.47007 13.7112H12.6899C13.4419 13.7112 14.0516 13.1015 14.0516 12.3495C14.0516 11.5974 13.4419 10.9878 12.6899 10.9878H10.7575L13.6528 8.0925C14.0422 7.70306 14.1587 7.11737 13.9479 6.60854C13.7372 6.09971 13.2407 5.76794 12.6899 5.76794H7.47007C6.71802 5.76794 6.10837 6.37759 6.10837 7.12963Z" fill="white"/>
                    </svg>
                )}
                name={"ZEPETO"}
                onClick={handleMoreClick}
            />
            <ZepetoPortfolioPhotoBox/>
        </PortfolioSlide>
    );
};

export default ZepetoPortfolioSlide;
