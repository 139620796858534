import classNames from "classnames";
import "./PageSlide.css";

const PageSlide = ({id, className, children}) => {
    return (
        <div id={id} className={classNames(className, "page-slide")}>
            {children}
        </div>
    );
};

export default PageSlide;
