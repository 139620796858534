import ServiceCard from "./ServiceCard";
import background from "./background_service_card_2.png";

const ServiceCard2 = ({active}) => {
    return (
        <ServiceCard
            no={2}
            active={active}
            title={<>메타버스<br/>공간 구축</>}
            detailTitle={
                <>
                    공간 기획서가<br/>
                    있으신가요? 제공해주신 공간기획서에 맞춰<br/>
                    공간을 구축합니다.
                </>
            }
            detailContent={
                <>
                    ZEPETO, ROBLOX,<br/>
                    Gather Town 등  모든 플랫폼에서<br/>
                    월드맵 구축이 가능합니다.<br/>
                    경쟁사 대비 압도적인 포트폴리오<br/>
                    개수에  기반을 두어 원하시는 맵을<br/>
                    높은 퀄리티로  생생하게<br/>
                    구현해냅니다.
                </>
            }
            detailSmallTitle={
                <>
                    공간 기획서가 있으신가요?<br/>
                    제공해주신 공간기획서에 맞춰<br/>
                    공간을 구축합니다.
                </>
            }
            detailSmallContent={
                <>
                    ZEPETO, ROBLOX, Gather Town 등<br/>
                    모든 플랫폼에서 월드맵 구축이 가능합니다.<br/>
                    경쟁사 대비 압도적인 포트폴리오 개수에<br/>
                    기반을 두어 원하시는 맵을 높은 퀄리티로<br/>
                    생생하게 구현해냅니다.
                </>
            }
            background={background}
            icon={(
                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M10.1734 12.6064C8.83908 13.2913 8 14.6652 8 16.165V31.81C8 33.3099 8.83908 34.6837 10.1734 35.3686L22.1734 41.528C23.32 42.1165 24.68 42.1165 25.8265 41.528L37.8265 35.3687C39.1609 34.6838 40 33.3099 40 31.81V16.1651C40 14.6652 39.1609 13.2913 37.8266 12.6064L25.8266 6.44713C24.68 5.85861 23.32 5.85861 22.1735 6.44713L10.1734 12.6064ZM15.0489 16.1253C14.1084 15.5461 12.8764 15.8389 12.2971 16.7794C11.7178 17.7199 12.0106 18.9519 12.9511 19.5311L22 25.1046V34.2531C22 35.3576 22.8954 36.2531 24 36.2531C25.1046 36.2531 26 35.3576 26 34.2531V25.1046L35.0489 19.5311C35.9894 18.9519 36.2822 17.7199 35.7029 16.7794C35.1236 15.8389 33.8916 15.5461 32.9511 16.1253L24 21.6386L15.0489 16.1253Z" fill="#0D0C0C" fillOpacity="0.2"/>
                </svg>
            )}
        />
    );
};

export default ServiceCard2;
