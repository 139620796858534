import ServiceCard from "./ServiceCard";
import background from "./background_service_card_4.png";

const ServiceCard4 = ({active}) => {
    return (
        <ServiceCard
            no={4}
            active={active}
            title={<>ZEPETO<br/>브랜딩<br/>아이템 제작</>}
            detailTitle={
                <>
                    ZEPETO에서 브랜드<br/>
                    아이템을  만들어<br/>
                    판매할 수 있습니다.
                </>
            }
            detailContent={
                <>
                    월 2억명의 사용자들에게 브랜딩<br/>
                    아이템을 배포하여 홍보 효과를<br/>
                    높여보세요. 기획부터 모델링, 출시,<br/>
                    이후 KPI 체크까지 모든 절차를<br/>
                    대행합니다.
                </>
            }
            detailSmallTitle={
                <>
                    ZEPETO에서 브랜드 아이템을<br/>
                    만들어 판매할 수 있습니다.
                </>
            }
            detailSmallContent={
                <>
                    월 2억명의 사용자들에게 브랜딩 아이템을<br/>
                    배포하여 홍보 효과를 높여보세요.<br/>
                    기획부터 모델링, 출시, 이후 KPI 체크까지<br/>
                    모든 절차를 대행합니다.
                </>
            }
            background={background}
            icon={(
                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.0006 5C13.7462 5 12.8014 6.14148 13.0359 7.37382L16.0801 23.3739C16.2596 24.3174 17.0844 25.0001 18.0448 25.0001H29.9551C30.9155 25.0001 31.7404 24.3174 31.9199 23.3739L34.9641 7.37382C35.1986 6.14148 34.2538 5 32.9993 5H15.0006Z" fill="#0D0C0C" fillOpacity="0.2"/>
                    <path d="M23.5247 34.937L24.0004 43.0001L17.1746 32.2373C16.745 31.5599 16.9328 30.6434 17.5895 30.2119L17.9542 29.9723C18.8748 29.3675 19.4341 28.3067 19.4341 27.1655C19.4341 27.0741 19.5046 27 19.5915 27H28.4093C28.4962 27 28.5667 27.0741 28.5667 27.1655C28.5667 28.3067 29.126 29.3675 30.0466 29.9723L30.4113 30.2119C31.068 30.6434 31.2558 31.5599 30.8262 32.2373L24.0004 43.0001L24.4761 34.937C25.2967 34.715 25.903 33.932 25.903 33C25.903 31.8955 25.0512 31 24.0004 31C22.9496 31 22.0978 31.8955 22.0978 33C22.0978 33.932 22.7041 34.715 23.5247 34.937Z" fill="#0D0C0C" fillOpacity="0.2"/>
                </svg>
            )}
        />
    )
};

export default ServiceCard4;
