import "./ContactProcessItem.css";

const ContactProcessItem = ({children}) => {
    return (
        <div className={"contact-process-item"}>
            <div className={"line"}/>
            <div className={"content"}>{children}</div>
        </div>
    );
};

export default ContactProcessItem;
