import ServiceCard1 from "./cards/ServiceCard1";
import ServiceCard2 from "./cards/ServiceCard2";
import ServiceCard3 from "./cards/ServiceCard3";
import ServiceCard4 from "./cards/ServiceCard4";
import "./ServicesContent.css";

const ServicesContent = () => {
    return (
        <div id={"services-content"}>
            <ServiceCard1/>
            <ServiceCard2/>
            <ServiceCard3/>
            <ServiceCard4/>
        </div>
    );
}

export default ServicesContent;
