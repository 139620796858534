import { useEffect, useState } from "react";
import { ref, onValue } from "firebase/database";
import { firebaseDB } from "../../../../../firebase";
import PortfolioDetailSlide from "../common/PortfolioDetailSlide";
import PortfolioDetailItem from "../common/item/PortfolioDetailItem";

const GatherTownPortfolioDetailSlide = () => {
  const [portfolio, setPortfolio] = useState([]);

  useEffect(() => {
    const listRef = ref(firebaseDB, "list/gather");
    onValue(
      listRef,
      (snapshot) => {
        const data = snapshot.val();
        if (data?.length) {
          setPortfolio(data);
        }
      },
      {
        onlyOnce: true,
      }
    );
  }, []);

  return (
    <PortfolioDetailSlide type={"gather-town"}>
      {portfolio.map((item, index) => (
        <PortfolioDetailItem
          title={item?.name}
          description={"게더타운 월드맵"}
          photo={item?.thumbnail}
          type={"gather"}
          index={index}
          key={index}
        />
      ))}
    </PortfolioDetailSlide>
  );
};

export default GatherTownPortfolioDetailSlide;
