import classNames from "classnames";
import "./MainHeaderMenuMultiLink.css";

const MainHeaderMenuMultiLink = ({items, children, active}) => {
    return (
        <div className={classNames("main-header-menu-link main-header-menu-multi-link", {active})}>
            <div className={"back"}/>
            <div className={"title"}>{children}</div>
            <div className={"dropdown"}>
                {items.map((item, index) => (
                    <a className={"eng"} key={index} href={item.link}>{item.name}</a>
                ))}
            </div>
        </div>
    )
};

export default MainHeaderMenuMultiLink;
