import "./HomeContent.css";
import HomeButtons from "./buttons/HomeButtons";
import PageSlideTitle from "../../PageSlideTitle";
import {observer} from "mobx-react-lite";
import classNames from "classnames";
import {useSlideAnimating} from "../../../../../hook/useSlideAnimating";
import HomeSwiperContent from "./swiper/HomeSwiperContent";

const HomeContent = observer(() => {
    const animating = useSlideAnimating('home');

    return (
        <div id={"home-content"}>
            <HomeSwiperContent/>
            <div className={"title-wrap"}>
                <PageSlideTitle className={"title"}>
                    Create Your&nbsp;
                    <span className={classNames('word', {animating})}>
                    &nbsp;
                        <span className={"text before"}>World</span>
                    <span className={"text after"}>Idea</span>
                    <div className={'mask mask-1'}>
                        <div className={'bar'}/>
                    </div>
                    <div className={'mask mask-2'}>
                        <div className={'bar'}/>
                    </div>
                    <div/>
                </span>
                    <br/>
                    With Devmate
                </PageSlideTitle>
                <HomeButtons/>
            </div>
        </div>
    );
});

export default HomeContent;
